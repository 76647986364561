import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { makeStyles } from '@material-ui/core';

import routes from 'src/routes/index';
import { renderRoutes } from 'src/routes/index';
import useAuth from 'src/hooks/useAuth';
import history from 'src/history';
import ErrorFallback, { myErrorHandler } from './ErrorFallback';
import ContentManager from 'src/componentes/content-manager';
import { ConfiguracaoSistemaProvider } from 'src/contexts/ConfiguracaoSistemaContext';

import './utils/microsoft-date-support';
import './styles/bootstrap.scss';
import './styles/app.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-K9HDLGQN9D');
ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
});

const useStyles = makeStyles((theme) => ({
  snackbar: {
    zIndex: 99999,
  },
}));

const App = () => {
  const auth = useAuth();
  const classes = useStyles();

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
    return () => {
      window.history.scrollRestoration = 'auto';
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={auth.reCaptchaSiteKey}
      container={{
        element: 'actio-recaptcha',
        parameters: { badge: 'bottomright' },
      }}
    >
      <ConfiguracaoSistemaProvider user={auth.user}>
        <IntlProvider
          locale={auth.user ? auth.user.idioma : 'en-us'}
          messages={auth.resources && auth.resources.messages}
        >
          <SnackbarProvider
            maxSnack={1}
            classes={{ containerRoot: classes.snackbar }}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={myErrorHandler}
            >
              <Router history={history}>{renderRoutes(routes)}</Router>
            </ErrorBoundary>
            <ContentManager name="default2" />
          </SnackbarProvider>
        </IntlProvider>
      </ConfiguracaoSistemaProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
