import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HelpCircle } from 'react-feather';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import useAuth from 'src/hooks/useAuth';
import { useIntl } from 'react-intl';

import ContentManager from 'src/componentes/content-manager';
import TermosUso from 'src/paginas/TermosUso';
import Sobre from './About';
import { capitalizeFirstLetter } from 'src/utils/string';
import { MODULOS } from 'src/utils/constants';
import HelpDrawer from './HelpDrawer';

const manuals = {
  'pt-br': {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/pt/topic/manual-de-treinamento-de-usuario',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/pt/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/pt/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/pt/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/pt/topic',
  },
  es: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/es/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/es/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/es/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/es/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/es/topic',
  },
  default: {
    [MODULOS.GESTAO_ESTRATEGICA]:
      'https://www.manula.com/manuals/stratec-informatica/gestao-estrategica-2-0/1/en/topic/user-training-manual',
    [MODULOS.GESTAO_RISCO]:
      'https://www.manula.com/manuals/stratec-informatica/belt/1/en/topic',
    [MODULOS.AVALIACAO_DESEMPENHO]:
      'https://www.manula.com/manuals/stratec-informatica/lift/1/en/topic',
    [MODULOS.MATRIZ_ORCAMENTARIA]:
      'https://www.manula.com/manuals/stratec-informatica/grid/1/en/topic',
    [MODULOS.REMUNERACAO_VARIAVEL]:
      'https://www.manula.com/manuals/stratec-informatica/score/1/en/topic',
  },
};

function NavbarHelp() {
  const intl = useIntl();
  const history = useHistory();
  const [currentManualLink, setCurrentManualLink] = useState('');
  const [openHelp, setOpenHelp] = useState(false);
  const { module } = useAuth();

  const handleTerms = () => {
    ContentManager.addContent(<TermosUso history={history} />);
  };

  const handleAbout = () => {
    ContentManager.addContent(<Sobre />);
  };

  const handleHelpDrawer = () => {
    setOpenHelp(true);
  };

  useEffect(() => {
    const localeManual = manuals[intl.locale] || manuals['default'];
    const current =
      localeManual[module?.id] || localeManual[MODULOS.GESTAO_ESTRATEGICA];
    setCurrentManualLink(current);
  }, [module, intl]);

  return (
    <div>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <HelpCircle />
        </DropdownToggle>
        <DropdownMenu right>
          <a
            style={{ color: 'inherit', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
            href={currentManualLink}
          >
            <DropdownItem>
              {intl.formatMessage({ id: 'manualUsuario' })}
            </DropdownItem>
          </a>
          {(module?.id == MODULOS.GESTAO_ESTRATEGICA ||
            module?.id == MODULOS.REMUNERACAO_VARIAVEL) && (
            <DropdownItem onClick={handleHelpDrawer}>
              {intl.formatMessage({ id: 'ajuda' })}
            </DropdownItem>
          )}
          <DropdownItem onClick={() => history.push('/notas-versao')}>
            {intl.formatMessage({ id: 'notasVersao' })}
          </DropdownItem>
          <DropdownItem onClick={handleAbout}>
            {intl.formatMessage({ id: 'sobre' })}
          </DropdownItem>
          <DropdownItem onClick={handleTerms}>
            {capitalizeFirstLetter(intl.formatMessage({ id: 'verTermosUso' }))}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <HelpDrawer openHelp={openHelp} setOpenHelp={setOpenHelp} module={module} />
    </div>
  );
}

export default NavbarHelp;
