import http from 'src/services/httpService';

export default {
  user: {
    fetchCurrentUser: () =>
      http.get('/Home/ObterUsuarioAtual').then((res) => res.data),
    getNotifications: (user) =>
      http.get('/Home/ObterNotificacoes', user).then((res) => res.data),
    getMessages: (user) =>
      http.get('/Home/ObterMensagens', user).then((res) => res.data),
    getTerms: () => http.get('/Home/ObterTermos').then((res) => res.data),
    getSettings: () =>
      http.get('/Home/ObterConfiguracoes').then((res) => res.data),
    setModule: (modulo) =>
      http.post('/Home/TrocarModulo', modulo).then((res) => res.data),
    setGanhosPorAcao: (valores, acaoId) =>
      http
        .post('/AcaoValor/SalvarValores', valores, acaoId)
        .then((res) => res.data),
  },
};
