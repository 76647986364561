import React from 'react';
import { autobind } from 'core-decorators';

import css from '../../utils/css';
import getInitials from '../../utils/getInitials';
import ConfigApp from 'src/services/configApp';
import ReactTooltip from 'react-tooltip';
@autobind
class Avatar extends React.Component {
  static defaultProps = {
    showLabel: true,
    border: 2,
    semEstilo: false,
    showInitials: true,
    labelWidth: null,
    borderColor: '#FFFFFF',
  };

  tratarUnidade(valor) {
    if (valor === undefined || valor === null) {
      return;
    }

    if (typeof valor === `string`) {
      if (valor.indexOf('%') !== -1) {
        return valor;
      } else if (valor.indexOf('px') !== -1) {
        return valor;
      } else if (valor.indexOf('em') !== -1) {
        return valor;
      }
    }

    return valor + 'px';
  }

  render() {
    let {
      label,
      size,
      labelSize,
      showLabel,
      border,
      semEstilo,
      foto,
      showInitials,
      showTooltip,
      id = 'avatar',
      labelLinkCallback,
      semMargin = false,
      transparent,
      labelColor,
      shadow,
      bold,
      fontSize,
      subLabel,
      borderColor,
      labelWidth,
      ...rest
    } = this.props;

    let classes = css`
      .main {
        display: flex;
        align-items: center;
      }

      .avatar {
        min-width: ${this.tratarUnidade(size) || 'auto'};
        min-height: ${this.tratarUnidade(size) || 'auto'};
        width: ${this.tratarUnidade(size) || 'auto'};
        height: ${this.tratarUnidade(size) || 'auto'};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .imgPerfil {
        min-width: ${this.tratarUnidade(size) || 'auto'};
        min-height: ${this.tratarUnidade(size) || 'auto'};
        width: ${this.tratarUnidade(size) || 'auto'};
        height: ${this.tratarUnidade(size) || 'auto'};
        font-size: ${this.tratarUnidade(labelSize) || 'auto'};
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .label {
        display: inline;
        margin-left: 10px;
        font-size: ${this.tratarUnidade(fontSize) || 'auto'};
      }
    `;

    return (
      <>
        <div
          id={id}
          data-tip={label}
          data-for={id}
          className={classes.main}
          {...rest}
        >
          {foto && foto.id > 0 ? (
            <img
              alt=""
              src={`${ConfigApp.ApiUrl}/ArquivoSistema/DownloadImagem?guid=${foto.guid}`}
              className={classes.avatar}
              style={{
                border: semEstilo
                  ? ''
                  : `${this.props.border}px solid ${borderColor}`,
                boxShadow: shadow ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
              }}
            />
          ) : (
            <span
              style={{
                border: semEstilo
                  ? ''
                  : `${this.props.border}px solid ${borderColor}`,
                marginTop: semMargin ? 0 : 2,
                background: transparent ? 'transparent' : '#BCBCBC',
                color: labelColor ?? '#fff',
                fontWeight: bold ? 'bold' : '',
                boxShadow: shadow ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : '',
                fontSize,
              }}
              className={classes.imgPerfil}
            >
              {label && (showInitials ? getInitials(label) : label)}
            </span>
          )}
          {showLabel && label && (
            <div style={{ maxWidth: labelWidth ?? '' }}>
              <div className={'text-truncate ml-2'}>
                {!labelLinkCallback ? (
                  label
                ) : (
                  <a
                    className="font-weight-bold text-primary cursor-pointer"
                    onClick={labelLinkCallback}
                  >
                    {label}
                  </a>
                )}
              </div>
              {subLabel && (
                <div
                  className={'text-truncate ml-2 font-weight-light text-muted'}
                  style={{ fontSize: '0.75rem' }}
                >
                  {<span>{subLabel}</span>}
                </div>
              )}
            </div>
          )}
        </div>
        {showTooltip && label && id && (
          <ReactTooltip id={id} aria-haspopup="true" />
        )}
      </>
    );
  }
}

export default Avatar;
