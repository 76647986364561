import { LANGUAGE_ADD, LANGUAGE_REMOVE } from '../types';

const language = (state = [], action) => {
  switch (action.type) {
    case LANGUAGE_ADD:
      return [...state, action.payload];
    case LANGUAGE_REMOVE:
      var list = state.map((item) => {
        if (item.locale != action.locale) return item;
        return null;
      });
      return list.filter((x) => x !== null && x !== undefined);
    default:
      return state;
  }
};

export default language;
