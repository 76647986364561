import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from 'src/componentes/LoadingScreen';

let keyCount = 0;

class ContentWrapper extends React.Component {
  shouldComponentUpdate(newProps) {
    return this.props.content !== newProps.content || this.props.index !== newProps.index;
  }

  render() {
    let { content, ...other } = this.props;

    return (
      <div ref="div">
        <Suspense fallback={<LoadingScreen />}>{React.cloneElement(content, other)}</Suspense>
      </div>
    );
  }
}

export default class ContentManager extends React.Component {
  static instances = {};

  static defaultProps = {
    name: `default`,
  };

  constructor(props) {
    super(props);
    ContentManager.instances[this.props.name] = this;

    this.state = {
      contents: [],
    };
  }

  componentWillUnmount() {
    delete ContentManager.instances[this.props.name];
  }

  static addContent(content, target = `default2`) {
    return ContentManager.instances[target].addContent(content);
  }

  static updateContent(key, newContent, target = `default2`) {
    ContentManager.instances[target].updateContent(key, newContent);
  }

  static removeContent(key, target = `default2`) {
    ContentManager.instances[target].removeContent(key);
  }

  static removeAllContentsByTarget(target = 'default2'){
    if(!ContentManager.instances || !ContentManager.instances[target]) return;

    const instance = ContentManager.instances[target];
    instance.removeAllContents();
  }

  addContent(content) {
    let key = (++keyCount).toString();
    this.setState({
      contents: this.state.contents.concat([
        {
          key: key,
          content: this.extendContent(content, key),
        },
      ]),
    });
    return key;
  }

  updateContent(key, content) {
    let holder = this.state.contents.filter((c) => c.key === key)[0];
    holder.content = this.extendContent(content, key);
    this.forceUpdate();
  }

  removeContent(key) {
    this.setState({
      contents: this.state.contents.filter((c) => c.key !== key),
    });
  }

  removeAllContents(){
    this.setState({
      contents: []
    });
  }

  extendContent(content, key) {
    return React.cloneElement(content, {
      remove: () => {
        this.removeContent(key);
      },
    });
  }

  render() {
    return (
      <div ref="div">
        {this.state.contents.map((h, index) => (
          <ContentWrapper key={h.key} content={h.content} index={index} />
        ))}
      </div>
    );
  }
}

ContentManager.propTypes = {
  name: PropTypes.string.isRequired,
};
