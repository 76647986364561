import React from 'react';
import { autobind } from 'core-decorators';
import css from '../../utils/css';
import classnames from 'classnames';
import PropTypes from 'prop-types';

let ids = 0;

const classes = css`
  .stickyContainer {
    position: relative;
  }
`;

@autobind
class Sticky extends React.Component {
  componentDidMount() {
    this.context.stickiesChanged();
  }

  componentWillUnmount() {
    this.context.stickiesChanged();
  }

  render() {
    let { children, component = 'div', ...other } = this.props;

    return React.createElement(
      component,
      {
        ref: 'sticky',
        'data-sticky-container': this.context.stickyContainerId,
        ...other,
      },
      children
    );
  }
}

@autobind
class StickyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.id = `${++ids}`;
  }

  resetScroll() {
    this.refs.container.scrollTop = 0;
  }

  getChildContext() {
    return {
      stickyContainerId: this.id,
      stickiesChanged: this.stickiesChanged,
    };
  }

  stickiesChanged() {
    this.stickies = null;
    if (!this.queuedUpdate) {
      this.queuedUpdate = true;
      requestAnimationFrame(() => {
        this.queuedUpdate = false;
        this.updateStickies();
      });
    }
  }

  componentWillUnmount() {
    this.stickies = null;
  }

  getElementTop(element) {
    let scrollContainer = this.refs.container;

    let top = -scrollContainer.scrollTop;

    let current = element;
    while (current && current !== scrollContainer) {
      top += current.offsetTop;
      current = current.offsetParent;
    }

    return top;
  }

  getNextSticky(stickies, sticky, index) {
    for (let i = index + 1; i < stickies.length; i++) {
      if (this.getStickySublevel(stickies[i]) <= this.getStickySublevel(sticky) && this.getStickyLevel(stickies[i]) == this.getStickyLevel(sticky)) {
        return stickies[i];
      }
    }
  }

  getStickyContainer(sticky) {
    if (sticky.dataset.container) {
      let container = parseInt(sticky.dataset.container, 10) || 0;
      let containerElement = sticky;
      for (let i = 0; i < container; i++) {
        containerElement = containerElement.parentNode;
      }
      return containerElement;
    }
  }

  getStickySublevel(sticky) {
    return (sticky.dataset.sublevel && parseInt(sticky.dataset.sublevel, 10)) || 0;
  }

  getStickyLevel(sticky) {
    return (sticky.dataset.level && parseInt(sticky.dataset.level, 10)) || 0;
  }

  updateStickies() {
    let scrollContainer = this.refs.container;

    if (!scrollContainer) {
      return;
    }

    if (!this.stickies) {
      this.stickies = scrollContainer.querySelectorAll(`[data-sticky-container='${this.id}']`);
    }

    let levelsBottom = {};

    for (let i = 0; i < this.stickies.length; i++) {
      let sticky = this.stickies[i];

      let stickyTop = this.getElementTop(sticky);
      let nextSticky = this.getNextSticky(this.stickies, sticky, i);
      let maxBottom = nextSticky ? this.getElementTop(nextSticky) : 999999;
      let container = this.getStickyContainer(sticky);
      if (container) {
        let containerBottom = this.getElementTop(container) + container.offsetHeight;
        maxBottom = Math.min(maxBottom, containerBottom);
      }

      let minTop = levelsBottom[this.getStickyLevel(sticky) - 1] || 0;

      let offset = 0;
      if (stickyTop < minTop) {
        offset = minTop - stickyTop;

        let maxOffset = maxBottom - sticky.offsetHeight - stickyTop;
        if (offset > maxOffset) {
          offset = maxOffset;
          this.addClass(sticky, `sticky-end`);
        } else {
          this.removeClass(sticky, `sticky-end`);
        }
      } else {
        this.removeClass(sticky, `sticky-end`);
      }

      sticky.style.transform = `translate(0, ${offset}px)`;

      if (offset > 0) {
        levelsBottom[this.getStickyLevel(sticky)] = stickyTop + offset + sticky.offsetHeight;

        this.addClass(sticky, `sticky`);
      } else {
        this.removeClass(sticky, `sticky`);
      }
    }
  }

  addClass(element, className) {
    if (!element.classList.contains(className)) {
      element.classList.add(className);
    }
  }

  removeClass(element, className) {
    if (element.classList.contains(className)) {
      element.classList.remove(className);
    }
  }

  render() {
    let { children, component = 'div', className, ...other } = this.props;

    return React.createElement(
      component,
      {
        ref: 'container',
        onScroll: this.updateStickies,
        className: classnames(classes.stickyContainer, className),
        ...other,
      },
      children
    );
  }
}

Sticky.contextTypes = StickyContainer.childContextTypes = {
  stickyContainerId: PropTypes.string,
  stickiesChanged: PropTypes.func,
};

export { Sticky, StickyContainer };
