import classnames from 'classnames';
import 'src/styles/icon/css/stratec-farois.css';
import 'src/styles/icon/css/stratec-modulos.scss';
import css from 'src/utils/css';
import 'src/styles/icon/css/icons.scss';

export default function SvgIcon(props) {
  let {
    children,
    className,
    icon,
    size = 18,
    color,
    colorHover,
    title,
    iconBadge,
    useMdiIcon = false,
    metaIconFontsize = 13,
    ehRelacionamento,
    ...other
  } = props;

  const baseClasses = css`
    .container {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 25px;
      .meta-icon {
        position: absolute;
        right: -13px;
        top: -5px;
        font-size: ${metaIconFontsize ? metaIconFontsize + 'px' : 'inherit'};
        &.mdi-check {
          color: #8bc53f;
        }
        &.mdi-exclamation {
          color: #ec1c24;
        }
      }
    }
  `;

  const classes = css`
    .container {
      font-size: ${size ? size + 'px' : 'inherit'};
      color: ${color || 'inherit'};
      &:hover {
        color: ${colorHover || 'inherit'};
      }
    }
  `.extend(baseClasses);

  let styleInline = {
    color: `${color}`,
    width: `${ehRelacionamento ? 22 + 'px' : size ? size + 'px' : 'inherit'}`,
    height: `${size ? size + 'px' : 'inherit'}`,
    backgroundSize: `${size ? size + 'px' : 'inherit'}`,
    display: 'inline-block',
    linHeight: 1,
    content: ' ',
  };

  const mainIcon = useMdiIcon
    ? `mdi mdi-${props.icon}`
    : `icon icon-${props.icon}`;

  return (
    <div {...other} className={classnames(classes.container, className)}>
      {iconBadge && (
        <i style={styleInline} className={`mdi mdi-${iconBadge} meta-icon`}></i>
      )}
      <i style={styleInline} className={mainIcon}></i>
    </div>
  );
}
