export function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
}
export function removeAllSpaces(string) {
  if (string) return string.replace(/ /g, '');
}
export function exists(variable) {
  return variable !== null && variable != null;
}

export function lowerCaseFirstLetter(string) {
  if (string) return string.charAt(0).toLowerCase() + string.slice(1);
}

export function lowerCaseAllLetter(string) {
  if (string) return string.toLowerCase();

  return string;
}

export function uperCaseAllLetter(string) {
  if (string) return string.toUpperCase();

  return string;
}

export function isNullOrWhiteSpace(string) {
  if (string === '0') return false;
  return !string || !string.trim();
}

/**
 * Decodifica uma query string em um objeto JS
 * @param {string} queryString ex: ?id=12321&txt=asldj
 * @returns { id: 12321, txt: 'asldj' }
 */
export function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

/**
 * Codifica um objeto JS em query string
 * @param {object} data ex: { id: 12321, txt: 'asldj' }
 * @returns id=12321&txt=asldj obs: sem o '?'
 */
export function encodeQueryData(data) {
  var ret = [];
  for (let d in data)
    if (data[d]) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
  return ret.join('&');
}
