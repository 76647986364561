import React, { useState, useRef } from 'react';
import Button from '../../componentes/button';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { useSnackbar } from 'notistack';

import Dialog from 'src/componentes/dialog';
import Input from 'src/componentes/input';
import http from 'src/services/httpService';
import LoadingContainer from 'src/componentes/loading-container';
import errorHandler from 'src/utils/error-handler';

const FormDemo = ({ module, ...rest }) => {
  const intl = useIntl();
  const ref = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});

  const handleChange = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const sendMessage = async () => {
    try {
      setIsLoading(true);
      const { nome, email, telefone, mensagem } = form;
      await http.post('Home/CriarEmailSoliciteUmaDemo', {
        nome,
        email,
        telefone,
        mensagem,
        moduloNome: module.nome,
      });

      enqueueSnackbar(intl.formatMessage({ id: 'label.mensagemSucesso' }), {
        variant: 'success',
      });

      handleClose();
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const isDisabled = !form || !form.nome || !form.email || !form.telefone || !form.mensagem;

  const actions = [
    <Button key={1} onClick={handleClose} color="secondary">
      {intl.formatMessage({ id: 'label.cancelar' })}
    </Button>,
    <Button key={2} disabled={isDisabled} color="primary" className="ml-2" onClick={sendMessage}>
      {intl.formatMessage({ id: 'label.enviar' })}
    </Button>,
  ];

  return (
    <Dialog ref={ref} title={module.nome} actions={actions} width={400} padContent onRequestClose={handleClose} {...rest}>
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={12}>
            <Input
              required
              model={{
                label: intl.formatMessage({ id: 'nome' }),
                value: form.nome,
                requestChange: (value) => handleChange('nome', value),
              }}
            />
          </Col>
          <Col md={12}>
            <Input
              type="email"
              required
              model={{
                label: intl.formatMessage({ id: 'email' }),
                value: form.email,
                requestChange: (value) => handleChange('email', value),
              }}
            />
          </Col>
          <Col md={12}>
            <Input
              required
              model={{
                label: intl.formatMessage({ id: 'telefone' }),
                value: form.telefone,
                requestChange: (value) => handleChange('telefone', value),
              }}
            />
          </Col>
          <Col md={12}>
            <Input
              rows={4}
              type="textarea"
              required
              model={{
                label: intl.formatMessage({ id: 'mensagem' }),
                value: form.mensagem,
                requestChange: (value) => handleChange('mensagem', value),
              }}
            />
          </Col>
        </Row>
      </LoadingContainer>
    </Dialog>
  );
};

export default FormDemo;
