var gestaoEstrategica = {
    Id: 1,
    Codigo: "gestaoEstrategica",
    Nome: "gestaoEstrategica",
    Icone: "seed",
    Url: "/",
    CorFundo: "#303F9F",
    CorTexto: "#FFF",
    Abreviacao: "GE"
  };
  
  var matrizOrcamentaria = {
    Id: 2,
    Codigo: "matrizOrcamentaria",
    Nome: "matrizOrcamentaria",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "MO"
  };
  
  var remuneracaoVariavel = {
    Id: 3,
    Codigo: "remuneracaoVariavel",
    Nome: "remuneracaoVariavel",
    Icone: "score",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "RV"
  };
  
  var avaliacaoDesempenho = {
    Id: 4,
    Codigo: "avaliacaoDesempenho",
    Nome: "avaliacaoDesempenho",
    Icone: "lift",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "Lift"
  };
  
  var gestaoDeRisco = {
    Id: 5,
    Codigo: "gestaoDeRisco",
    Nome: "gestaoDeRisco",
    Icone: "belt",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "Belt"
  };
  
  var powerBI = {
    Id: 6,
    Nome: "powerBI",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "Lift"
  };
  
  var escritorioProjetos = {
    Id: 7,
    Codigo: "escritorioProjetos",
    Nome: "escritorioProjetos",
    Url: "/",
    CorFundo: "#EF6C00",
    CorTexto: "#FFF",
    Abreviacao: "EP"
  };
  
  var inteligenciaArtificial = {
    Id: 8,
    Codigo: "inteligenciaArtificial",
    Nome: "inteligenciaArtificial",
    Url: "/",
    CorFundo: "#303F9F",
    CorTexto: "#FFF",
    Abreviacao: "IA"
  };
  
  export const MODULOS_DESCRICAO = {
    GestaoEstrategica: gestaoEstrategica,
    MatrizOrcamentaria: matrizOrcamentaria,
    RemuneracaoVariavel: remuneracaoVariavel,
    AvaliacaoDesempenho: avaliacaoDesempenho,
    GestaoDeRisco: gestaoDeRisco,
    PowerBI: powerBI,
    EscritorioProjetos: escritorioProjetos,
    InteligenciaArtificial: inteligenciaArtificial
  };