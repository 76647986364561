import { EXCEPTION_CANCELLATION_TOKEN } from 'src/utils/constants';
import ContentManager from 'src/componentes/content-manager';

let lastPathname = null;

// Armazenamento de todas as requests feitas no software via axios.
let activeRequests = [];

// Endpoints ou rotas que precisam ser executadas independente da troca de rotas.
const acceptedEndpointsOrKeyWords = [
  'Home/Me',
  'Upload',
  'Download',
  'Exportar',
  'Export',
  'Importar',
  'Logout',
  'GerarArquivo',
  'GerarRelatorio',
  'GerarPDF',
  'ExportToPdf',
  'ExportarTAP',
  'DownloadRelatorio',
  'ExportarRelatorioCardMetas'
];

const setCurrentPathname = (newPathname) => {
  if (!lastPathname) {
    lastPathname = newPathname;
    return;
  }

  if (newPathname === lastPathname) return;

  ContentManager.removeAllContentsByTarget();

  axiosCancelManager.cancelAllRequests(newPathname);
  lastPathname = newPathname;
};

// Factory
const axiosCancelManager = {
  // Registrando requests e os pathnames
  addRequest: (requestUrl, cancelFn, pathname) => {
    activeRequests.push({
      requestUrl,
      pathname,
      cancelFn,
    });
  },
  // Cancelando todas as requisições antigas (não relacionadas ao pathname atual)
  cancelAllRequests: (currentPathname) => {
    const oldRequests = activeRequests.filter(
      (x) => x.pathname != currentPathname
    );

    for (const activeRequest of oldRequests) {
      const { requestUrl, cancelFn } = activeRequest;

      const isAcceptedUrl =
        acceptedEndpointsOrKeyWords.find((x) => requestUrl.includes(x)) != null;

      if (!isAcceptedUrl) {
        try {
          cancelFn(EXCEPTION_CANCELLATION_TOKEN);
        } catch (_) {}
      }
    }

    activeRequests = [];
  },
};

export { setCurrentPathname, axiosCancelManager };
