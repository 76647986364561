import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';

const matrizPageSteps = (intl, resources) => {

  const feature = {
    id: 2,
    descricao: 'matrizResultados',
  };

  return [
    {
      selector: '.matriz-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'visualizeItens' })
      ),
    },
    {
      selector: '.matriz-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'pesquiseItens' })
      ),
    },
    {
      selector: '.matriz-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'encontreItem' },
          { area: resources.area, responsavel: resources.responsavel }
        )
      ),
    },
    {
      selector: '.matriz-step4',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeTelaCheiaMatriz' },
          { matrizresultados: resources.matrizderesultados }
        )
      ),
    },
    {
      selector: '.matriz-step5',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'visaoVerticalHorizontal' },{ matrizresultados: resources.matrizderesultados })
      ),
    },
    {
      selector: '.matriz-step6',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'busquePorFiltros' })
      ),
    },
    {
      selector: '.matriz-step7',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'exporteMatriz' },
          { matrizresultados: resources.matrizderesultados }
        )
      ),
    },
    {
      selector: '.matriz-step8',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'ordeneColunas' })
      ),
    },
    {
      selector: '.matriz-step9',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'visualizeValoresEmGrafico' })
      ),
    },
    {
      selector: '.matriz-step10',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'salvarAlteracoes' })
      ),
    },
    {
      selector: '.matriz-feedback',
      content: <FeedbackTour feature={feature} intl={intl} />,
    },
  ];
};

export default matrizPageSteps;
