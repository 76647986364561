import { NOTIFICATIONS_SET } from '../types';

export default function notifications(state = [], action = {}) {
  switch (action.type) {
    case NOTIFICATIONS_SET:
      return action.notifications;
    default:
      return state;
  }
}
