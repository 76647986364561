const LogoModulo = ({ module, active, size = 42 }) => {
  const renderLogo = (m) => (
    <img
      className="mt-auto mb-auto"
      style={{ height: 'auto', width: size, borderRadius: 0 }}
      alt="ModuleLogo"
      src={`/modulos/${m.icone}${active ? '-negativo' : ''}.svg`}
    />
  );

  const renderLogoDefault = (m) => (
    <div
      style={{ width: size }}
      className={`d-flex rounded-lg ${
        active ? 'bg-primary' : 'bg-secondary'
      } border-white border text-white`}
    >
      <div className="ml-auto mr-auto">{m.abreviacao}</div>
    </div>
  );

  return module.icone ? renderLogo(module) : renderLogoDefault(module);
};

export default LogoModulo;
