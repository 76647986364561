import { Redirect, useLocation } from 'react-router-dom';
import qs from 'query-string';
import useAuth from 'src/hooks/useAuth';

const GuestGuard = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, utilizandoAdfs, getLoginAdfs } = useAuth();
  const windowLocation = window.location;

  const acessoExterno =
    windowLocation && qs.parse(windowLocation.search).externo
      ? qs.parse(windowLocation.search).externo === 'S'
        ? true
        : false
      : false;
  const isApp =
    windowLocation && qs.parse(windowLocation.search).isApp
      ? qs.parse(windowLocation.search).isApp === 'true'
        ? true
        : false
      : false;

  if (isAuthenticated && !acessoExterno) {
    if (location?.state?.from?.pathname) {
      return (
        <Redirect
          to={{
            pathname: location?.state?.from?.pathname,
            search: location?.state?.from?.search,
          }}
        />
      );
    } else return <Redirect to="/" />;
  } else if (
    utilizandoAdfs &&
    acessoExterno === false &&
    location.pathname.toUpperCase() != '/VERIFYADFS' &&
    location.pathname.toUpperCase() != '/RELATORIO-AUTH' &&
    location.pathname.toUpperCase() != '/LOGOUT'
  ) {
    getLoginAdfs(isApp);
  }

  return <>{children}</>;
};

export default GuestGuard;
