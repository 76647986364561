import { FormGroup, Label, Input } from 'reactstrap';
import { injectIntl } from 'react-intl';

function Checkbox({ cursor,intl, model, disabled, getAlteracao, ...rest }) {
  const handleChange = (e) => {
    model.requestChange && model.requestChange(e.target.checked);

    if (getAlteracao != null) {
      getAlteracao();
    }
  };

  return (
    <FormGroup
      check
      validationstate={model && model.errors ? 'error' : null}
      {...rest}
    >
      <Label check style={rest.styleLabel}>
        <Input
          type="checkbox"
          disabled={disabled}
          checked={model.value}
          onChange={handleChange}
          style={{ marginBottom: 10, cursor: cursor }}
        />
        {model.label &&
          (model.label.props
            ? intl.formatMessage({ ...model.label.props })
            : model.label)}
      </Label>
    </FormGroup>
    // <CustomInput
    //   type="checkbox"
    //   disabled={disabled}
    //   checked={model.value}
    //   label={
    //     model.label &&
    //     (
    //       model.label.props
    //       ?
    //       intl.formatMessage({...model.label.props})
    //       :
    //       model.label
    //     )
    //   }
    //   onBlur={() => console.log('teste')}
    //   onChange={(e) => handleChange(e)}
    //   {...rest}
    // />
  );
}

export default injectIntl(Checkbox);

// @autobind
// class CheckBox extends React.Component {
//   handleChange(e) {
//     this.props.model.requestChange && this.props.model.requestChange(e.target.checked);

//     if(this.props.getAlteracao != null){
//       this.props.getAlteracao()
//     }
//   }

//   render() {
//     let {
//       className,
//       model,
//       disabled,
//       style,
//       intl,
//       onBlur,
//       addons,
//       height,
//       width,
//       ...rest
//     } = this.props;

//     return (
//       <FormGroup style={style} className={className} validationstate={model && model.errors ? 'error' : null} {...rest}>
//         <div className="checkbox c-checkbox">
//           <label>
//             {model != null && <Input onBlur={onBlur} type="checkbox" disabled={disabled} checked={model.value} onChange={this.handleChange}/>}
//             <span className="mdi mdi-check" style={{height: height && height, width: width && width}}></span>
//             {
//               model.label &&
//               (
//                 model.label.props
//                 ?
//                 intl.formatMessage({...model.label.props})
//                 :
//                 model.label
//               )
//             }

//           </label>
//         </div>
//         { model && model.erros && <Alert>{model.erros}</Alert> }
//         {addons && addons}
//       </FormGroup>
//     );
//   }
// }
// export default injectIntl(CheckBox);
