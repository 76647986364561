import { ReactComponent as Clap } from 'src/styles/icon/icons/clap.svg';
import { ReactComponent as Cycle } from 'src/styles/icon/icons/cycle.svg';
import { ReactComponent as Controles } from 'src/styles/icon/icons/controles.svg';
import { ReactComponent as Indicador } from 'src/styles/icon/icons/indicador.svg';
import { ReactComponent as ChatBubbleLeftRight } from 'src/styles/icon/icons/chat-bubble-left-right.svg';

const svgs = {
  clap: Clap,
  cycle: Cycle,
  controles: Controles,
  indicador: Indicador,
  chatBubbleLeftRight: ChatBubbleLeftRight,
};

export default svgs;
