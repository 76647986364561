const Content = ({ children }) => (
  <div
    id="mainContent"
    className="content p-0"
    style={{
      marginTop: 82,
    }}
  >
    {children}
  </div>
);

export default Content;
