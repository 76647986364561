import { useTour } from '@reactour/tour';
import { useHistory } from 'react-router-dom';
import Button from 'src/componentes/button';
import { capitalizeFirstLetter } from 'src/utils/string';
import { useIntl } from 'react-intl';

function TourController({ setOpenHelp, redirectPath, steps }) {
  const { setIsOpen, setCurrentStep, setSteps } = useTour(); 
  const history  = useHistory();
  const intl = useIntl();

  const handleClick = () => {
    setOpenHelp(false); 
    history.push(redirectPath);
    setSteps(steps); 
    setIsOpen(true);
    setCurrentStep(0);
  };

  return (
    <Button className="tour-button" onClick={handleClick}>
      {capitalizeFirstLetter(intl.formatMessage({ id: 'iniciar' }))}
    </Button>
  );
}

export default TourController;

