import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import useAuth from 'src/hooks/useAuth';
import Button from 'src/componentes/button';
import Checkbox from 'src/componentes/checkbox';
import Confirm from 'src/componentes/NewConfirm';
import ContentManager from 'src/componentes/content-manager';
import Dialog from 'src/componentes/dialog';

const Accept = ({ history, ...rest }) => {
  const intl = useIntl();
  const ref = useRef();

  const { aceitouTermosUso, setTermosUso } = useAuth();

  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  function handleLinkTerms() {
    if (intl.locale === 'pt-br') return 'https://actiosoftware.com/privacidade';

    if (intl.locale === 'es') return 'https://actiosoftware.com/es/privacidad';

    return 'https://actiosoftware.com/en/privacy-policy';
  }

  const renderAccept = () => {
    const handleChangeStatus = (status) => {
      handleClose();
      setTermosUso(status);
      history?.push('/');
    };

    return (
      <>
        <p>{intl.formatMessage({ id: 'confiraNossosTermos' })}</p>
        <p>
          <a href={handleLinkTerms()} target="_blank" rel="noopener noreferrer" style={{ color: '#4372C4' }}>
            {handleLinkTerms()}
          </a>
        </p>
        <Checkbox
          style={{ margin: 0 }}
          model={{
            label: intl.formatMessage({ id: 'liAceitoTermos' }),
            value: checked,
            requestChange: (v) => setChecked(v),
          }}
        />
        <div className="d-flex justify-content-center mt-4">
          <Button color="secondary" className="mr-2" outline onClick={() => handleChangeStatus(false)}>
            {intl.formatMessage({ id: 'recusar' })}
          </Button>
          <Button onClick={() => handleChangeStatus(true)} disabled={!checked}>
            {intl.formatMessage({ id: 'avancar' })}
          </Button>
        </div>
      </>
    );
  };

  const renderRefuse = () => {
    const handleCancel = () => {
      handleClose();
    };

    const handleOk = () => {
      handleCancel();
      setTermosUso(false);
      history?.push('/');
    };

    const handleConfirmRefuse = () => {
      ContentManager.addContent(
        <Confirm
          handleCancel={handleClose}
          primaryMessage={intl.formatMessage({ id: 'termosUso' })}
          secondaryMessage={intl.formatMessage({ id: 'desejaRealmenteConfirmarRecusarTermos' })}
          onSaved={handleOk}
        />
      );
    };

    return (
      <>
        <p>{intl.formatMessage({ id: 'voceJaAceitouTermos' })}</p>
        <p>
          <a href={handleLinkTerms()} target="_blank" style={{ color: '#4372C4' }}>
            {handleLinkTerms()}
          </a>
        </p>
        <p>
          <span className="font-weight-bolder">{intl.formatMessage({ id: 'casoOptePorRecusarTermos' })}</span>{' '}
          {intl.formatMessage({ id: 'casoOptePorRecusarTermos2' })}
        </p>
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={handleConfirmRefuse}>{intl.formatMessage({ id: 'recusar' })}</Button>
          <Button color="secondary" className="ml-2" outline onClick={handleClose}>
            {intl.formatMessage({ id: 'label.cancelar' })}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog ref={ref} onRequestClose={handleClose} {...rest}>
      <div className="text-center pb-4 px-4" style={{ maxWidth: 450 }}>
        <div className="mb-4">
          <img alt="Logo Actio" src="/icone.png" style={{ width: 140 }} />
          <h1 style={{ margin: 0, fontSize: 30 }} className="font-weight-bolder mt-3">
            {intl.formatMessage({ id: 'termosUso' })}
          </h1>
        </div>
        {aceitouTermosUso ? renderRefuse() : renderAccept()}
      </div>
    </Dialog>
  );
};

export default Accept;
