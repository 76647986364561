import React from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'reactstrap';
import MdiIcon from '../mdi-icon';

@autobind
class ButtonComponent extends React.Component {
  static defaultProps = {
    color: 'primary',
  };

  render() {
    let { type, size, leftIcon, rightIcon, color, iconSize, ...other } = this.props;

    return (
      <Button color={color} {...other}>
        <div className="d-flex align-items-center justify-content-center">
          {leftIcon && <MdiIcon style={{ marginTop: 2 }} size={iconSize ? iconSize : 13} icon={leftIcon} className="mr-2" />}
          {this.props.children}
          {rightIcon && <MdiIcon style={{ marginTop: 2 }} size={iconSize ? iconSize : 13} icon={rightIcon} className="mr-1" />}
        </div>
      </Button>
    );
  }
}
export default ButtonComponent;
