export const LOCALE_SET = 'LOCALE_SET';

export const LANGUAGE_ADD = 'LANGUAGE_ADD';
export const LANGUAGE_REMOVE = 'LANGUAGE_REMOVE';

export const TERMS_SET = 'TERMS_SET';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_AUTH_TYPE = 'USER_AUTH_TYPE';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_CONFIG_SET = 'USER_CONFIG_SET';
export const USER_FETCHED = 'USER_FETCHED';
export const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET';
export const MESSAGES_SET = 'MESSAGES_SET';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';

export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_MODULE = 'SET_MODULE';
export const SET_FOTO = 'SET_FOTO';

export const USER_TOKEN = 'USER_TOKEN';
export const USER_EXTERNAL_TOKEN = 'USER_EXTERNAL_TOKEN';

export const DATA_ULTIMA_REQUISICAO = 'DATA_ULTIMA_REQUISICAO';

export const FILTERED_ITEM = 'FILTERED_ITEM';
export const SET_EXPANDED_ITEM = 'SET_EXPANDED_ITEM';
export const UPDATE_EXPANDED_ITEMS = 'UPDATE_EXPANDED_ITEMS';

export const INC_NUMBER_RENDERED_PAGES = 'INC_NUMBER_RENDERED_PAGES';
export const SET_CURRENT_PRESENTATION = 'SET_CURRENT_PRESENTATION';

export const GANHOSPORACAO_SET = 'GANHOSPORACAO_SET';
export const UPDATE_GANHOSPORACAO = 'UPDATE_GANHOSPORACAO';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const TOKEN_EXPIRATION_TIME = 'tokenExpirationTime';
export const LAST_REQUEST_DATE = 'lastRequestDate';
export const EXTERNAL_ACCESS_TOKEN = 'externalAccessToken';
export const MFA_TOKEN = 'MFAToken';
export const TEMP_CLEAR = 'TEMP_CLEAR';
