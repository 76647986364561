import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import Dialog from 'src/componentes/dialog';

const About = ({ ...rest }) => {
  const intl = useIntl();
  const ref = useRef();

  const { versaoSoftware } = useSelector((state) => state.user);

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  return (
    <Dialog ref={ref} onRequestClose={handleClose} {...rest}>
      <div className="pb-4 px-4" style={{ maxWidth: 450 }}>
        <div className="mb-4 text-center">
          <img alt="Logo Actio" src="/icone.png" style={{ width: 140 }} />
        </div>
        <div>
          <p>{intl.formatMessage({ id: 'label.textoActioP1' })}</p>
          <p>{intl.formatMessage({ id: 'label.textoActioP2' })}</p>
          <div className="d-flex align-items-center mb-3">
            <span>{intl.formatMessage({ id: 'label.textoActioP3' })}</span>
            <img className="ml-3" alt="Falconi" src="/falconi.png" style={{ height: 30, width: 85 }} />
          </div>
          <p>{intl.formatMessage({ id: 'label.textoActioP4' })}</p>
          <hr />
          <div className="text-center">
            <span className="font-weight-bold">{intl.formatMessage({ id: 'label.versaoAplicacao' })}:</span>
            <span className="font-weight-bold ml-1" style={{ color: '#00BF6F' }}>
              {versaoSoftware}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default About;
