import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';

export const NavbarDropdown = ({ children, count, showBadge, header, footer, onFooterClick, icon: Icon }) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted cursor-pointer" onClick={onFooterClick}>
          {footer}
        </span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const NavbarDropdownItem = ({ icon, title, description, time, spacing, ...rest }) => (
  <ListGroupItem {...rest}>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? 'pl-2' : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        {time && <div className="text-muted small mt-1">{moment(time).fromNow()}</div>}
      </Col>
    </Row>
  </ListGroupItem>
);
