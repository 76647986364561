export default class NameMap {
  extend(other) {
    let result = new NameMap();

    for (let k in other) {
      result[k] = other[k];
    }

    for (let k in this) {
      if (result[k]) {
        result[k] += ' ' + this[k];
      } else {
        result[k] = this[k];
      }
    }

    return result;
  }
}
