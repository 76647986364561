import React, { useEffect } from 'react';
import { Bell } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { setNotifications } from 'src/actions/notifications';
import { NavbarDropdown, NavbarDropdownItem } from './NavbarDropdown';

function NavbarNotification() {
  const intl = useIntl();
  const history = useHistory();
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNotifications());
  }, [dispatch]);

  const handleAllClick = () => history.push('/minhas-informacoes/notificacao');

  const handleNotificationClick = (notification) => {
    history.push({
      pathname: '/minhas-informacoes/notificacao',
      search: `?tipoId=${notification?.tipo.id}`,
    });
  };

  return (
    <NavbarDropdown
      header={intl.formatMessage({ id: 'label.novasNotificacoes' })}
      footer={intl.formatMessage({ id: 'exibirTodasNotificacoes' })}
      icon={Bell}
      count={notifications ? notifications.length : 0}
      showBadge={notifications && notifications.length > 0}
      onFooterClick={handleAllClick}
    >
      {notifications &&
        notifications.length > 0 &&
        notifications.map((item, key) => {
          return (
            <NavbarDropdownItem
              key={key}
              icon={<Bell size={18} className="text-warning" />}
              title={item.tipo.descricao}
              description={`${item.quantidadeNaoLidas} ${intl.formatMessage({ id: 'label.novasNotificacoes' })}`}
              spacing
              onClick={() => handleNotificationClick(item)}
            />
          );
        })}
    </NavbarDropdown>
    // <>
    //   <UncontrolledDropdown nav inNavbar className="mr-2">
    //     <DropdownToggle nav className="nav-icon dropdown-toggle">
    //       <div className="position-relative">
    //         <Bell className="align-middle" size={18} />
    //         {notifications && totalNotificacoes > 0 &&
    //           <span className="indicator">
    //             {totalNotificacoes > 5 ? '5+' : totalNotificacoes}
    //           </span>
    //         }
    //       </div>
    //     </DropdownToggle>
    //     <DropdownMenu right className="dropdown-menu-lg py-0">
    //       {
    //         totalNotificacoes > 0 &&
    //         <div className="dropdown-menu-header position-relative">
    //           {totalNotificacoes} {<FormattedMessage id="label.notificacoes" />}
    //         </div>
    //       }
    //       <ListGroup>
    //         {
    //           notifications && totalNotificacoes > 0 && notifications.map((notificacao, index) => (
    //             <ListGroupItem action key={index} onClick={handleNotificationClick.bind(this, notificacao)}>
    //               <div className="media">
    //                 <div className="align-self-start mr-2">
    //                   <em className="mdi mdi-bell-outline text-info"></em>
    //                 </div>
    //                 <div className="media-body">
    //                   <p className="m-0">{notificacao.tipo.descricao}</p>
    //                   <p className="m-0 text-muted text-sm">{notificacao.quantidadeNaoLidas} <FormattedMessage id="label.novasNotificacoes" /></p>
    //                 </div>
    //               </div>
    //             </ListGroupItem>
    //           ))
    //         }
    //         <ListGroupItem action onClick={handleAllNotifications}>
    //           <span className="d-flex align-items-center">
    //             <span className="text-sm"><FormattedMessage id="label.maisNotificacoes" /></span>
    //             <span className="badge badge-danger ml-auto">{totalNotificacoes}</span>
    //           </span>
    //         </ListGroupItem>
    //       </ListGroup>
    //     </DropdownMenu>
    //   </UncontrolledDropdown>
    // </>
  );
}

export default NavbarNotification;
