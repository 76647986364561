Date.prototype.toJSON = function () {
  let clone = new Date(this.valueOf());
  let currentTimezoneOffsetMinutes = this.getTimezoneOffset();
  let timezoneTimeAdjustment = currentTimezoneOffsetMinutes * 60 * 1000;
  clone.setTime(clone.getTime() - timezoneTimeAdjustment);
  return clone.toISOString();
};

JSON.parse = (function () {
  let oldParse = JSON.parse;
  return function (text, reviver) {
    return oldParse(text, function (key, value) {
      let regex = /\/Date\(([-+]?\d+)([-+]\d+)?\)\//gi;
      let match = regex.exec(value);
      if (match) {
        let time = parseInt(match[1], 10);
        value = new Date(time);
        let currentTimezoneOffsetMinutes = value.getTimezoneOffset();
        let timezoneTimeAdjustment = currentTimezoneOffsetMinutes * 60 * 1000;
        if (match[2]) {
          let dateTimezoneOffsetMinutes = (parseInt(match[2], 10) / 100) * 60;
          timezoneTimeAdjustment += dateTimezoneOffsetMinutes * 60 * 1000;
        }
        value.setTime(value.getTime() + timezoneTimeAdjustment);
      }
      if (reviver) value = reviver(key, value);
      return value;
    });
  };
})();
