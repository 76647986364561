function getFormatedURL() {
  const endpoint = process.env.REACT_APP_SERVER + '/api';

  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment) return endpoint;

  const hostname = window.location.host;

  if (
    hostname.includes('.vercel.app') ||
    hostname.includes('.actiosoftware.app') ||
    hostname.includes('.actiosoftware.net') ||
    hostname.includes('.actiosoftware.dev')
  ) {
    const currentHost = hostname
      .replace(`.vercel.app`, '')
      .replace(`.actiosoftware.app`, '')
      .replace(`.actiosoftware.net`, '')
      .replace(`.actiosoftware.dev`, '');

    const newEndpoint = endpoint.replace(
      /^https:\/\//,
      'https://' + currentHost + '.'
    );

    return newEndpoint;
  }

  return '/api';
}

const ConfigApp = {
  ApiUrl: getFormatedURL(),
  ApiTimeOut: process.env.REACT_APP_API_TIMEOUT,
};

export default ConfigApp;
