import React from 'react';
import css from '../../utils/css';
import Spinner from '../spinner';

let classes = css`
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 5;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    max-height: 600px;
  }

  .spinner {
     {
      /* position: absolute; */
    }
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg:not(:root) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
`;

export default function LoadingOverlay(props) {
  return (
    <div className={classes.overlay} style={{ zIndex: props.zIndex ? props.zIndex : null }}>
      <div className={classes.container}>
        <Spinner className={classes.spinner} />
      </div>
    </div>
  );
}
