import { MESSAGES_SET } from '../types';

export default function messages(state = [], action = {}) {
  switch (action.type) {
    case MESSAGES_SET:
      return action.messages;
    default:
      return state;
  }
}
