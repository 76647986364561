import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import Avatar from 'src/componentes/avatar';

function NavbarUser() {
  const history = useHistory();
  const intl = useIntl();

  const { user, logout} = useAuth();

  const renderAvatar = () => (
    <Avatar
      foto={user?.fotoArquivo}
      size={35}
      showLabel={false}
      label={user?.nome}
    />
  );

  return (
    <UncontrolledDropdown nav inNavbar>
      <span className="d-inline-block d-sm-none">
        <DropdownToggle nav caret>
          {renderAvatar()}
        </DropdownToggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <DropdownToggle className="d-flex align-items-center" nav>
          <div className="mr-2 text-sm text-right text-dark">
            <div>{intl.formatMessage({ id: 'bemVindo' })}!</div>
            <strong>{user?.nome}</strong>
          </div>
          {renderAvatar()}
        </DropdownToggle>
      </span>
      <DropdownMenu right>
        <DropdownItem
          onClick={() => history.push('/minhas-informacoes/meus-dados')}
        >
          {intl.formatMessage({ id: 'label.meusDados' })}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={logout}>
          {intl.formatMessage({ id: 'label.sair' })}
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default NavbarUser;
