import { useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import http from 'src/services/httpService';
import Button from 'src/componentes/button';
import Checkbox from 'src/componentes/checkbox';
import Dialog from 'src/componentes/dialog';

const AvisoMFA = ({ history, ...rest }) => {
  const intl = useIntl();
  const ref = useRef();

  const setVisualizouAvisoMFA = async (view) => {
    try {
      await http.post('/Colaborador/AlterarVisualizouAvisoMFA', {
        visualizou: view,
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleSend = () => {
    handleClose();
    setVisualizouAvisoMFA(true);
  };

  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  function handleLink() {
    if (intl.locale === 'pt-br')
      return 'https://actiosoftware.com/verificacaoemduasetapas/';

    if (intl.locale === 'es') return 'https://actiosoftware.com/es/2fa-latam/';

    return 'https://actiosoftware.com/en/2fa-north-america/';
  }

  const renderAccept = () => {
    return (
      <>
        <p>{intl.formatMessage({ id: 'explicacaoMFA' })}</p>

        <div
          className="p-2 mt-4 mb-4 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: 'rgba(29, 119, 137, 0.08)',
            borderRadius: 4,
          }}
        >
          <div className="text-dark m-0 d-flex flex-column">
            <span>{intl.formatMessage({ id: 'paraHabilitarMFAAcesse' })}</span>
            <span className="font-weight-bolder">
              {intl.formatMessage({ id: 'caminhoHabilitarMFA' })}
            </span>
          </div>
        </div>

        <div className="d-flex flex-column mb-3">
          <span className="font-weight-bolder">
            {intl.formatMessage({ id: 'entendaSobreMFA' })}
          </span>

          <a
            href={handleLink()}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#4372C4' }}
          >
            {handleLink()}
          </a>
        </div>

        <Checkbox
          className="font-weight-bold"
          style={{ margin: 0 }}
          model={{
            label: intl.formatMessage({
              id: 'confirmoNecessidadeHabilitarMFA',
            }),
            value: checked,
            requestChange: (v) => setChecked(v),
          }}
        />

        <div className="d-flex justify-content-center mt-4">
          <Button onClick={handleSend} disabled={!checked}>
            {intl.formatMessage({ id: 'label.confirmar' })}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Dialog ref={ref} onRequestClose={handleClose} {...rest}>
      <div className="text-center pb-4 px-4" style={{ maxWidth: 450 }}>
        <div className="mb-4">
          <img
            alt="Actio Alert"
            src="/alert.svg"
            style={{ width: 37, height: 94 }}
          />

          <h1
            style={{ margin: 0, fontSize: 22 }}
            className="font-weight-bolder mt-4"
          >
            {intl.formatMessage({ id: 'tituloAvisoMFA' })}
          </h1>
        </div>

        {renderAccept()}
      </div>
    </Dialog>
  );
};

export default AvisoMFA;
