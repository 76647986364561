import { GANHOSPORACAO_SET, UPDATE_GANHOSPORACAO } from '../types';

export function SetSalvarGanhosPorAcao(state = [], action = {}) {
  switch (action.type) {
    case GANHOSPORACAO_SET:
      return {
        ...state,
        setGanhosPorAcao: action.setGanhosPorAcao,
      };
    default:
      return state;
  }
}

export default function ganhosPorAcao(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_GANHOSPORACAO:
      return {
        ...state,
        valoresGanhosPorAcao: action.valoresGanhosPorAcao,
      };
    default:
      return state;
  }
}
