import React from 'react';
import focusManager from '../../utils/focus-manager';

export default class FocusTrap extends React.Component {
  componentDidMount() {
    this.updateFocusContainer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trap !== this.props.trap) {
      this.updateFocusContainer();
    }
  }

  updateFocusContainer() {
    if (this.props.trap) {
      this.setFocusContainer();
    } else {
      this.removeFocusContainer();
    }
  }

  setFocusContainer() {
    focusManager.addContainer(this.refs.container, this.props.trap);
  }

  removeFocusContainer() {
    focusManager.removeContainer(this.refs.container);
  }

  componentWillUnmount() {
    this.removeFocusContainer();
  }

  render() {
    let { component = 'div', children, trap, ...other } = this.props;

    return React.createElement(
      component,
      {
        ref: 'container',
        ...other,
      },
      children
    );
  }
}
