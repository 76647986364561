export default function stringify(content) {
  let lines = [];

  function writeContent(innerContent, indentation = '') {
    for (let i = 0; i < innerContent.length; i++) {
      let item = innerContent[i];

      if (typeof item == 'string') {
        lines.push(`${indentation}${item};`);
      } else {
        lines.push(`${indentation}${item.header} {`);
        writeContent(item.content, `${indentation}  `);
        lines.push(`${indentation}}`);
      }
    }
  }

  writeContent(content);

  return lines.join('\n');
}
