import React from 'react';
import classnames from 'classnames';
import LoadingOverlay from '../loading-overlay';
import css from '../../utils/css';

let classes = css`
  .loadingContainer {
    position: relative;
    height: 100%;
    &.padContent {
      padding: 15px;
    }

    &.dock {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .loadingOnly {
    position: unset;
  }
`;

export default function LoadingContainer(props) {
  let { className, dock, padContent, isLoading, onlyLoading, ...other } = props;

  return (
    <div {...other} className={classnames(classes.loadingContainer, className, { padContent, dock }, onlyLoading ? classes.loadingOnly : '')}>
      {(!onlyLoading || (onlyLoading && !isLoading)) && props.children}
      {isLoading && <LoadingOverlay zIndex={props.zIndex} />}
    </div>
  );
}
