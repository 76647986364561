import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setCurrentPathname } from 'src/services/httpServiceManager';

import useAuth from '../hooks/useAuth';

const AuthGuard = ({ children }) => {
  const {
    isAuthenticated,
    utilizandoAdfs,
    isApp,
    loginAlreadyTriggered,
    isLogout,
  } = useAuth();

  const location = useLocation();
  const windowLocation = window.location;

  if (!isAuthenticated) {
    return utilizandoAdfs && isLogout ? (
      <Redirect to={{ pathname: '/logout' }} />
    ) : loginAlreadyTriggered ? (
      <Redirect to={{ pathname: '/login' }} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: location } }} />
    );
  }

  useEffect(() => {
    if (
      (windowLocation?.pathname?.toUpperCase() == '/LOGOUT' ||
        windowLocation?.pathname?.toUpperCase() == '/REDIRECT-TO-APP') &&
      isApp &&
      utilizandoAdfs
    ) {
      window.location.href = 'actioapp://main';
    }
  }, [windowLocation]);

  useEffect(() => {
    setCurrentPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
