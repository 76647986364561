export default function unnest(content, parentSelectors) {
  let properties = [];
  let groups = [];

  for (let i = 0; i < content.length; i++) {
    let item = content[i];

    if (typeof item === 'string') {
      properties.push(item);
    } else if (item.header.indexOf('@media') === 0) {
      let contentResult = unnest(item.content, parentSelectors);

      if (contentResult.groups.length) {
        groups.push({
          header: item.header,
          content: contentResult.groups,
        });
      }
    } else {
      let selectors = item.header.split(/\s*,\s*/g);

      if (parentSelectors) {
        selectors = [].concat.apply(
          [],
          parentSelectors.map((parentSelector) => {
            return selectors.map((selector) => {
              if (selector.indexOf('&') !== -1) {
                return selector.replace(/\&/g, parentSelector);
              } else {
                return `${parentSelector} ${selector}`;
              }
            });
          })
        );
      }

      let contentResult = unnest(item.content, selectors);

      if (contentResult.properties.length) {
        groups.push({
          header: selectors.join(', '),
          content: contentResult.properties,
        });
      }

      if (contentResult.groups.length) {
        groups.push.apply(groups, contentResult.groups);
      }
    }
  }

  return { properties, groups };
}
