import { MESSAGES_SET } from '../types';
import api from '../api';
import errorHandler from '../utils/error-handler';

export const messagesSet = (messages) => ({
  type: MESSAGES_SET,
  messages,
});

export const setMessages = () => (dispatch) =>
  api.user
    .getMessages()
    .then((res) => {
      dispatch(messagesSet(res));
    })
    .catch((error) => {
      errorHandler(error);
    });
