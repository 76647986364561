import axios from 'axios';
import ConfigApp from 'src/services/configApp';
import authStorage from 'src/services/authStorage';
import { getSession, setSession } from 'src/contexts/AuthContext';
import errorHandler from '../utils/error-handler';
import { endpoints } from 'src/api/endpoints';
import { axiosCancelManager } from './httpServiceManager';
import { manager as cardMetasManager } from './cardMetaItemPermissions';

let isRefreshing = false;
let isLoggingOut = false;

let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers?.reverse()?.forEach((callback) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const http = axios.create({
  baseURL: ConfigApp.ApiUrl,
  timeout: ConfigApp.ApiTimeOut,
});

const callLogout = async (refreshToken) => {
  if (isLoggingOut) return;

  isLoggingOut = true;

  errorHandler({ type: 'EXPIRED_SESSION' });
  await http.post(`/Home/Logout`, { refreshToken });

  setSession(null);
  window.location.href = '/';
};

const callRetry = async (originalRequest) => {
  return new Promise((resolve) => {
    addSubscriber((access_token) => {
      originalRequest.headers.Authorization = `Bearer ${access_token}`;

      // Corrige o Bug da baseUrl ser só '/api', então a requisição estava sendo feita como /api/api
      if (
        originalRequest.baseURL == '/api' &&
        originalRequest.url.startsWith('/api')
      ) {
        originalRequest.url = originalRequest.url.substring(
          4,
          originalRequest.url.length
        );
      }

      resolve(axios(originalRequest));
    });
  });
};

http.interceptors.request.use(
  async (config) => {
    const { accessToken } = getSession();
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    // Esta opção irá permitir o envio e recebimento de Cookie através do Cors
    // Cookie é utilizado para definição da linguagem do Usuário
    config.withCredentials = true;
    // TODO: Verificar pq precisa desta configuração
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const { encryptedData, iv } = await cardMetasManager.getList();

    if (encryptedData) {
      config.headers.AllowedItemIdsByCardMetas = encryptedData;
      config.headers.AllowedItemIdsByCardMetas_IV = iv;
    }

    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain) {
      config.headers.common['X-Tenant-ID'] = subdomain;
    }

    const { url } = config;
    const { baseURL } = http.defaults;
    const controller = url.replace(baseURL, '');
    const endpoint = endpoints[controller];

    if (endpoint) config.baseURL = endpoint;

    config.cancelToken = new axios.CancelToken((cancel) => {
      axiosCancelManager.addRequest(
        config.url,
        cancel,
        window.location.pathname
      );
    });

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    authStorage.setLastRequestDate();
    return response;
  },
  async (error) => {
    const {
      accessToken,
      refreshToken,
      tokenExpirationTime: minutesToLogout,
    } = getSession();

    try {
      const {
        config,
        response: { status },
      } = error;

      const originalRequest = config;

      if (status === 403) setSession(null);
      else if (status === 401) {
        if (accessToken && minutesToLogout && refreshToken) {
          const lastRequestDate = authStorage.getLastRequestDate();

          const difference = new Date() - lastRequestDate;
          const dateDifference = new Date(difference);
          const minutesDifference = dateDifference.getTime() / 1000 / 60;

          const timeExpired =
            minutesToLogout != null && minutesDifference >= minutesToLogout;

          // se for maior que o limite de minutos do software, já desconecta.
          if (timeExpired) return callLogout(refreshToken);

          if (!originalRequest._retry) {
            if (isRefreshing) return callRetry(originalRequest);

            isRefreshing = true;
            originalRequest._retry = true;

            const response = await axios.post(
              `${ConfigApp.ApiUrl}/Home/ObterRefreshToken`,
              {
                refreshToken,
              }
            );

            const jwt = response.data;
            const newToken = jwt?.token;

            setSession({
              accessToken: newToken,
              refreshToken: jwt?.refreshToken,
              tokenExpirationTime: jwt?.expiraTokenMinutos,
            });

            onAccessTokenFetched(newToken);

            isRefreshing = false;

            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            return axios(originalRequest);
          }
        }
      }
    } catch (error) {}

    return Promise.reject(error);
  }
);

export default http;
