import NameMap from './name-map';

let styleCount = 0;

let replaceRegexes = [/^(\.)([A-Za-z-0-9\-_]+)()$/g, /^(\@\S*?keyframes\s+)([A-Za-z-0-9\-_]+)()$/g];

export default function scopify(content) {
  let scopeId = `style${++styleCount}`;

  let nameMap = new NameMap();

  for (let i = 0; i < content.length; i++) {
    let item = content[i];

    item.header = item.header
      .split(/\s*,\s*/g)
      .map((selector) => {
        for (let r = 0; r < replaceRegexes.length; r++) {
          let regex = replaceRegexes[r];
          regex.lastIndex = 0;
          let result = regex.exec(selector);
          if (result) {
            let name = result[2];
            let newName = `${scopeId}-${name}`;
            nameMap[name] = newName;
            return selector.replace(regex, `$1${newName}$3`);
          }
        }

        console.warn(`The selector ${item.header} cannot be scopified`);
        return '';
      })
      .join(', ');
  }

  return nameMap;
}
