import { NOTIFICATIONS_SET } from '../types';
import api from '../api';
import errorHandler from '../utils/error-handler';

export const notificationsSet = (notifications) => ({
  type: NOTIFICATIONS_SET,
  notifications,
});

export const setNotifications = () => (dispatch) =>
  api.user
    .getNotifications()
    .then((res) => {
      dispatch(notificationsSet(res));
    })
    .catch((error) => {
      errorHandler(error);
    });
