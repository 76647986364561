import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';
import MdiIcon from 'src/componentes/mdi-icon';

const colors = ['#B72224', '#D52029', '#E95223', '#EA6F22', '#F6A726', '#FDC729', '#EBDB0A', '#E5E144', '#C2D234', '#AFC940', '#66B44E'];

const NPS = () => {
  const intl = useIntl();
  const { setPesquisaNPS } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const [display, setDisplay] = useState(false);

  const [form, setForm] = useState({});

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setDisplay(true);
      }, 2000);
    } else {
      setTimeout(() => {
        setDisplay(false);
      }, 500);
    }
  }, []);

  const handleChangeForm = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleClose = () => setIsOpen(false);

  const handleSend = () => {
    handleClose();
    setPesquisaNPS({ nota: form.nota, texto: form.texto });
  };

  const renderItem = (color, index) => {
    const isSelected = form.nota === index;
    const noneSelected = form.nota == null;

    return (
      <div
        onClick={() => handleChangeForm('nota', index)}
        className="d-inline-flex justify-content-center align-items-center text-white rounded-lg cursor-pointer opacity-hover"
        style={{ height: 35, width: 35, background: color, opacity: isSelected || noneSelected ? 1 : 0.3 }}
      >
        {index}
      </div>
    );
  };

  const sendIsDisabled = !form.nota;

  return display ? (
    <div
      className="p-4 shadow-xl border position-fixed font-weight-bold bg-white text-center rounded-top-lg overflow-hidden"
      style={{
        zIndex: 100,
        bottom: 0,
        right: 20,
        maxWidth: 550,
        display: `${display ? 'block' : 'none'}`,
        animation: `${isOpen ? 'openBottom' : 'closeBottom'} 0.5s ease-in-out forwards`,
      }}
    >
      <div className="d-flex justify-content-end mb-3">
        <MdiIcon className="cursor-pointer" onClick={handleClose} icon="close" />
      </div>
      <div>{intl.formatMessage({ id: 'pesquisaNPSPergunta' })}</div>
      <div className="d-flex justify-content-around mt-3 mb-4">{colors?.map((color, index) => renderItem(color, index))}</div>
      <div>{intl.formatMessage({ id: 'pesquisaNPSMotivo' })}</div>
      <div className="d-flex align-items-center mt-3">
        <div style={{ flexGrow: 6, flexBasis: 0 }}>
          <Input
            style={{ marginBottom: '-1rem' }}
            model={{
              label: '',
              value: form.texto,
              requestChange: (v) => handleChangeForm('texto', v),
            }}
          />
        </div>
        <a
          onClick={() => !sendIsDisabled && handleSend()}
          className={`ml-3 ${sendIsDisabled ? 'text-muted' : 'text-primary cursor-pointer'}`}
          style={{ flexGrow: 1, flexBasis: 0, textDecoration: sendIsDisabled ? 'none' : '' }}
        >
          {intl.formatMessage({ id: 'label.enviar' })}
        </a>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NPS;
