import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import errorHandler from 'src/utils/errorHandler';
import { useSnackbar } from 'notistack';
import guid from 'src/utils/guid';

function ErrorMessage({ error }) {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(errorHandler(error, intl), {
      variant: 'error',
      key: guid(),
    });
  }, [error]);

  return <></>;
}

export default ErrorMessage;
