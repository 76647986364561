export default function MaterialIcon({ icon, classCustom, outline, symbol, size = 20 }) {
  return (
    <span
      class={`material-${symbol ? 'symbols' : 'icons'}${
        outline ? '-outlined' : ''
      } md-${size} ${classCustom}`}
    >
      {icon}
    </span>
  );
}
