import { Redirect } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

const AddonGuard = ({ parameters, children }) => {
  const { addon } = parameters;
  const { addons } = useAuth();

  const hasActiveAddon = addons && addons.find((m) => m.id === addon)?.ativo;

  if (!hasActiveAddon) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default AddonGuard;
