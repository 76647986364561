import { FILTERED_ITEM, SET_EXPANDED_ITEM, UPDATE_EXPANDED_ITEMS } from '../types';

export default function relationshipsTree(state = {}, action = {}) {
  switch (action.type) {
    case FILTERED_ITEM:
      return {
        ...state,
        item: action.item,
        expandedChildItems: [],
      };
    case SET_EXPANDED_ITEM:
      return {
        ...state,
        expandedChildItems: [...state.expandedChildItems, action.expandedChildItem],
      };
    case UPDATE_EXPANDED_ITEMS:
      return {
        ...state,
        expandedChildItems: action.expandedChildItems,
      };
    default:
      return state;
  }
}
