import { createContext, useEffect, useReducer } from 'react';
import moment from 'moment';

import ContentManager from 'src/componentes/content-manager';
import http from 'src/services/httpService';
import SplashScreen from 'src/componentes/SplashScreen';
import { userLoggedIn, moduleFetched } from 'src/actions/users';
import { useDispatch } from 'react-redux';
import authStorage from 'src/services/authStorage';
import errorHandler from 'src/utils/error-handler';
import TermosUso from 'src/paginas/TermosUso';
import SenhaForte from 'src/paginas/colaborador/SenhaForte';
import AvisoMFA from 'src/paginas/AvisoMFA';
import defaultMessages from 'src/lang/default.json';
import { setCurrentPathname } from 'src/services/httpServiceManager';
import { UNKNOWN_PATHNAME } from 'src/utils/constants';
import { manager as cardMetasManager } from 'src/services/cardMetaItemPermissions';

import 'moment/locale/tr';
import 'moment/locale/ar';
import 'moment/locale/pt-br';
import 'moment/locale/nl';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';

const initialAuthState = {
  loginAlreadyTriggered: false,
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  resources: null,
  module: null,
  modules: [],
  addons: [],
  terms: null,
  isApp: false,
  configurations: null,
};

export const setSession = (jwt, isExternalAccess = false, mfaJwt = null) => {
  if (jwt) {
    if (isExternalAccess) authStorage.setExternalAccessToken(jwt.accessToken);
    else authStorage.setAccessToken(jwt.accessToken);

    authStorage.setRefreshToken(jwt.refreshToken);
    authStorage.setTokenExpirationTime(jwt.tokenExpirationTime);

    http.defaults.headers.common.Authorization = `Bearer ${jwt.accessToken}`;

    if (mfaJwt) {
      authStorage.setMFAToken(mfaJwt.token);
    }
  } else {
    if (isExternalAccess) authStorage.removeExternalAccessToken();
    else authStorage.removeAccessToken();

    authStorage.removeRefreshToken();
    authStorage.removeTokenExpirationTime();
    delete http.defaults.headers.common.Authorization;

    setCurrentPathname(UNKNOWN_PATHNAME);
    cardMetasManager.clearEverything();
  }
};

export const getSession = () => {
  authStorage.clearAllStorage(); //TODO: remover este método após 1 mês de uso

  const accessToken = authStorage.getAccessToken();
  const refreshToken = authStorage.getRefresToken();
  const tokenExpirationTime = authStorage.getTokenExpirationTime();
  const externalAccessToken = authStorage.getExternalAccessToken();

  return {
    accessToken,
    refreshToken,
    tokenExpirationTime,
    externalAccessToken,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const {
        isAuthenticated,
        user,
        resources,
        module,
        modules,
        addons,
        terms,
        aceitouTermosUso,
        respondeuPesquisaNps,
        moduloInteligenciaArtificial,
        excluido,
        utilizandoAdfs,
        imagemLogo,
        configurations,
        reCaptchaSiteKey,
        loginAlreadyTriggered = false,
        decimalSeparator,
        thousandsSeparator,
        isLogout,
        respondeuPesquisaCSAT,
      } = action.payload;
      return {
        ...state,
        isAuthenticated,
        loginAlreadyTriggered: state.loginAlreadyTriggered
          ? true
          : loginAlreadyTriggered,
        isInitialised: true,
        user,
        resources,
        module,
        modules,
        addons,
        terms,
        aceitouTermosUso,
        respondeuPesquisaNps,
        moduloInteligenciaArtificial,
        excluido,
        utilizandoAdfs,
        imagemLogo,
        configurations,
        reCaptchaSiteKey,
        decimalSeparator,
        thousandsSeparator,
        isLogout,
        respondeuPesquisaCSAT,
      };
    }
    case 'LOGIN': {
      const {
        user,
        resources,
        module,
        modules,
        addons,
        terms,
        aceitouTermosUso,
        respondeuPesquisaNps,
        moduloInteligenciaArtificial,
        excluido,
        configurations,
        decimalSeparator,
        thousandsSeparator,
        isLogout,
        respondeuPesquisaCSAT,
      } = action.payload;

      return {
        ...state,
        isAuthenticated: !excluido,
        loginAlreadyTriggered: true,
        user,
        resources,
        module,
        modules,
        addons,
        terms,
        aceitouTermosUso,
        respondeuPesquisaNps,
        moduloInteligenciaArtificial,
        excluido,
        configurations,
        decimalSeparator,
        thousandsSeparator,
        isLogout: false,
        respondeuPesquisaCSAT,
      };
    }
    case 'LOGOUT': {
      const { utilizandoAdfs } = action.payload;

      return {
        ...state,
        isAuthenticated: false,
        respondeuPesquisaNps: true,
        user: null,
        module: null,
        modules: [],
        addons: [],
        utilizandoAdfs,
        isLogout: true,
      };
    }
    case 'SETMODULE': {
      const { module } = action.payload;
      return {
        ...state,
        isInitialised: true,
        module,
      };
    }
    case 'SETMODULES': {
      const { modules } = action.payload;
      return {
        ...state,
        isInitialised: true,
        modules,
      };
    }
    case 'LOADING': {
      const { isInitialised } = action.payload;
      return {
        ...state,
        isInitialised,
      };
    }
    case 'SETTERMOSUSO': {
      const { aceitouTermosUso } = action.payload;
      return {
        ...state,
        aceitouTermosUso,
      };
    }
    case 'SETPESQUISANPS': {
      const { respondeuPesquisaNps } = action.payload;
      return {
        ...state,
        respondeuPesquisaNps,
      };
    }
    case 'SETPESQUISACSAT': {
      const { respondeuPesquisaCSAT } = action.payload;
      return {
        ...state,
        respondeuPesquisaCSAT,
      };
    }
    case 'SETBRANDIMAGE': {
      const { imagemLogo } = action.payload;
      return {
        ...state,
        imagemLogo,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  login: () => {
    Promise.resolve();
  },
  logout: () => {},
  loginAdfs: () => {},
  setTermosUso: async () => {},
  setPesquisaNPS: async () => {},
  setPesquisaCSAT: async () => {},
  getLoginAdfs: () => {},
  loginUnificado: () => {},
  loginAcessoExterno: () => {},
  loginAcessoConsultaExterna: () => {},
  autenticacaoExterna: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatchRedux = useDispatch();

  const clientUrl = window?.location?.origin;

  const setUser = (model, isApp = false) => {
    if (!model) return;

    const {
      usuario,
      jwt,
      mfaJwt,
      resources,
      moduloAtual,
      modulosDisponiveis,
      addonsDisponiveis,
      termos,
      appInfoModel,
      utilizandoAdfs,
    } = model;
    const token = {
      accessToken: jwt?.token,
      refreshToken: jwt?.refreshToken,
      tokenExpirationTime: jwt?.expiraTokenMinutos,
    };

    setSession(token, false, mfaJwt);

    const {
      aceitouTermosUso,
      visualizouAlerta,
      visualizouAvisoMFA,
      respondeuPesquisaNps,
      moduloInteligenciaArtificial,
      excluido,
      tiposAnexos,
      locale,
      decimalSeparator,
      thousandsSeparator,
      respondeuPesquisaCSAT,
    } = appInfoModel;

    if (appInfoModel) {
      // TODO: remover este bind do redux, pois não vamos salvar o usuário mais no redux e sim com context api, mas para
      // manter compatibilidade em todo software, vamos fazer o bind do usuário no redux.
      dispatchRedux(userLoggedIn(appInfoModel));
    }
    moment.locale(usuario.idioma);

    if (isApp) {
      let expirationDate = new Date();
      expirationDate.setMinutes(
        expirationDate.getMinutes() + jwt.expiraTokenMinutos
      );
      expirationDate = moment(expirationDate)
        .locale(locale)
        .format('YYYY-MM-DD HH:mm:ss');
      const redirectURL = `actioapp://main?accessToken=${jwt.token}&expiration=${expirationDate}&language=${locale}`;
      window.location.href = redirectURL;
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: usuario,
        resources,
        module: moduloAtual,
        modules: modulosDisponiveis,
        addons: addonsDisponiveis,
        terms: termos,
        aceitouTermosUso,
        visualizouAlerta,
        visualizouAvisoMFA,
        respondeuPesquisaNps: respondeuPesquisaNps && !isApp,
        moduloInteligenciaArtificial,
        excluido,
        respondeuPesquisaCSAT: respondeuPesquisaCSAT && !isApp,
        configurations: {
          tiposAnexos,
        },
        decimalSeparator,
        thousandsSeparator,
      },
    });

    if (aceitouTermosUso == null && !isApp)
      ContentManager.addContent(<TermosUso />);

    if (visualizouAlerta == null || (visualizouAlerta == false && !isApp))
      ContentManager.addContent(<SenhaForte />);

    if (
      !utilizandoAdfs &&
      (visualizouAvisoMFA == null || (visualizouAvisoMFA == false && !isApp))
    ) {
      ContentManager.addContent(<AvisoMFA />);
    }
  };

  const getLoginAdfs = async (isApp) => {
    try {
      const response = await http.post('/Authentication/LoginAdfs', {
        isApp: isApp,
      });
      window.location.href = response.data;
    } catch (error) {
      errorHandler(error);
    }
  };

  const loginAdfs = (model, isApp) => {
    setUser(model, isApp);
  };

  const loginUnificado = (token, codigo, MFAToken) => {
    return new Promise((resolve, reject) => {
      http
        .post('/Home/LoginUnificado', { token, codigo, MFAToken })
        .then((response) => {
          const data = response.data;
          const model = { ...data };

          setUser(model);
          resolve(model);
        })
        .catch((error) => reject(error));
    });
  };

  const autenticacaoExterna = async (token) => {
    const response = await http.post('/Home/AutenticacaoExterna', { token });
    const model = { ...response.data };
    setUser(model);
  };

  const login = async (email, password, code, captchaToken, MFAToken) => {
    const response = await http.post('/Home/NewLogin', {
      email,
      senha: password,
      codigo: code,
      urlCliente: clientUrl,
      tokenCaptcha: captchaToken,
      MFAToken,
    });

    const data = await response.data;

    setUser(data);
  };

  const loginAcessoConsultaExterna = async (
    token,
    codigo,
    objetoId,
    tipoId
  ) => {
    const response = await http.post('/Home/LoginAcessoConsutaExterna', {
      token,
      codigo,
      objetoId,
      tipoId,
    });
    const model = { ...response.data };
    setUser(model);
  };

  const loginAcessoExterno = async () => {
    const response = await http.post('/Home/LoginAcessoExterno');
    const model = { ...response.data };
    setUser(model);
  };

  const logoutAdfs = async (isApp = false) => {
    const response = await http.post('/Authentication/LogoutAdfs', {
      isApp: isApp,
    });
    if (response && response.data) window.location.href = response.data;
  };

  const logout = async (isApp = false) => {
    try {
      setCurrentPathname(UNKNOWN_PATHNAME);
      cardMetasManager.clearEverything();
      localStorage.setItem('RELACIONAMENTO-ITENS', JSON.stringify([]));

      const response = await http.post('/Home/Logout', { isApp: isApp });
      const result = response.data;
      if (!result) return;

      setSession(null);
      dispatch({
        type: 'LOGOUT',
        payload: { utilizandoAdfs: result.utilizandoAdfs },
      });

      if (result.utilizandoAdfs == true) {
        logoutAdfs(isApp);
      } else {
        //dispatchRedux(userLoggedOut(result));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const setModule = async (newModule) => {
    dispatch({
      type: 'LOADING',
      payload: {
        isInitialised: false,
      },
    });
    const response = await http.post('/Home/TrocarModulo', {
      moduloModel: newModule,
    });
    const { jwt, moduloAtual, modulosDisponiveis } = response.data;

    // TODO: remover este bind do redux, pois não vamos salvar o usuário mais no redux e sim com context api, mas para
    // manter compatibilidade em todo software, vamos fazer o bind do usuário no redux.
    dispatchRedux(moduleFetched(moduloAtual));

    dispatch({
      type: 'SETMODULE',
      payload: {
        module: moduloAtual,
      },
    });

    dispatch({
      type: 'SETMODULES',
      payload: {
        modules: modulosDisponiveis,
      },
    });

    setSession({
      accessToken: jwt.token,
      refreshToken: jwt.refreshToken,
      tokenExpirationTime: jwt.expiraTokenMinutos,
    });

    if (document.getElementById('actio-recaptcha'))
      document.getElementById('actio-recaptcha').style.visibility = 'hidden';
  };

  const setTermosUso = async (accept) => {
    try {
      await http.post('/Colaborador/AlterarTermosUso', {
        aceitar: accept,
      });

      dispatch({
        type: 'SETTERMOSUSO',
        payload: {
          aceitouTermosUso: accept,
        },
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const setPesquisaNPS = async (model) => {
    try {
      await http.post('/PesquisaNps/Salvar', {
        model: model,
      });

      dispatch({
        type: 'SETPESQUISANPS',
        payload: {
          respondeuPesquisaNps: true,
        },
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const setPesquisaCSAT = async (model) => {
    try {
      await http.post('/PesquisaCSAT/Salvar', {
        model: model,
      });

      dispatch({
        type: 'SETPESQUISACSAT',
        payload: {
          respondeuPesquisaCSAT: true,
        },
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  const setBrandImage = async (imgLogo) => {
    dispatch({
      type: 'SETBRANDIMAGE',
      payload: {
        imagemLogo: imgLogo,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        let locale = navigator.language;
        const clientUrl = window?.location?.origin;
        const clientUrlFull = window?.location?.href;
        const currentSession = getSession();
        const { accessToken } = currentSession;

        if (accessToken && authStorage.isValidToken(accessToken)) {
          if (document.getElementById('actio-recaptcha'))
            document.getElementById('actio-recaptcha').style.visibility =
              'hidden';
          const tokenHasLanguage = authStorage.tokenHasLanguage(accessToken);
          if (tokenHasLanguage) setSession(currentSession);
          const response = await http.post('/Home/Me', {
            clientUrl,
            clientUrlFull,
          });
          const {
            usuario,
            resources,
            moduloAtual,
            modulosDisponiveis,
            termos,
            appInfoModel,
            reCaptchaSiteKey,
            addonsDisponiveis,
          } = response.data;

          const {
            aceitouTermosUso,
            respondeuPesquisaNps,
            moduloInteligenciaArtificial,
            excluido,
            tiposAnexos,
            decimalSeparator,
            thousandsSeparator,
            jwt,
            respondeuPesquisaCSAT,
          } = appInfoModel;
          const imagemLogo = appInfoModel.configuracao.imagemLogo;
          if (!tokenHasLanguage) {
            const token = {
              accessToken: jwt?.token,
              refreshToken: jwt?.refreshToken,
              tokenExpirationTime: jwt?.expiraTokenMinutos,
            };

            setSession(token, false);
          }

          moment.locale(usuario.idioma);

          // TODO: remover este bind do redux, pois não vamos salvar o usuário mais no redux e sim com context api, mas para
          // manter compatibilidade em todo software, vamos fazer o bind do usuário no redux.
          dispatchRedux(userLoggedIn(appInfoModel));

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: !excluido,
              loginAlreadyTriggered: true,
              user: usuario,
              resources,
              module: moduloAtual,
              modules: modulosDisponiveis,
              addons: addonsDisponiveis,
              terms: termos,
              aceitouTermosUso,
              respondeuPesquisaNps,
              moduloInteligenciaArtificial,
              excluido,
              imagemLogo,
              respondeuPesquisaCSAT,
              configurations: {
                tiposAnexos,
              },
              reCaptchaSiteKey: reCaptchaSiteKey,
              decimalSeparator,
              thousandsSeparator,
            },
          });
        } else {
          const response = await http.post('/Home/GetResource', { locale });
          const { messages, utilizandoAdfs, reCaptchaSiteKey } = response.data;
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
              resources: { messages },
              module: null,
              modules: [],
              addons: [],
              terms: null,
              utilizandoAdfs: utilizandoAdfs,
              reCaptchaSiteKey: reCaptchaSiteKey,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            resources: defaultMessages,
            module: null,
            modules: [],
            addons: [],
            terms: null,
          },
        });
        logout();
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        setModule,
        setTermosUso,
        setPesquisaNPS,
        setPesquisaCSAT,
        loginAdfs,
        getLoginAdfs,
        setBrandImage,
        loginUnificado,
        loginAcessoExterno,
        loginAcessoConsultaExterna,
        autenticacaoExterna,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
