import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';

export function ErrorFallback({ error, resetErrorBoundary }) {
  const intl = useIntl();

  const getFormatedErrorMessage = () => {
    let errorMessage = intl.formatMessage({ id: 'label.ocorreuUmErro' });

    if (error.response) {
      if (error.response.data && error.response.data.errorMessage == 98) {
        return Promise.reject(error.response.data);
      }

      if (error.response.status === 400) {
        errorMessage = error.response.data;
      }

      if (error.response.data.errorMessage) {
        errorMessage = error.response.data.errorMessage;
      }
    } else {
      if (error.type == 'EXPIRED_PASSWORD') {
        errorMessage = intl.formatMessage({ id: 'label.suaSenhaExpirou' });
      }
    }

    return errorMessage;
  };
  return (
    <div className="mh-100 h-100 w-100 bg-light text-dark d-flex flex-column align-items-center justify-content-center">
      <p>
        {intl.formatMessage({
          id: 'ocorreuUmErroInesperadoAoRealizarARequisicao',
        })}
      </p>
      <pre>"{getFormatedErrorMessage()}"</pre>
      <Button onClick={resetErrorBoundary}>
        {intl.formatMessage({ id: 'tentarNovamente' })}
      </Button>
    </div>
  );
}

export function myErrorHandler(error, info) {
  // Do something with the error
  console.log('error', error);
  // E.g. log to an error logging client here
}

export default ErrorFallback;
