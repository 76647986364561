import React from 'react';
import css from '../../utils/css';
import classnames from 'classnames';

const offset = 187;
const durationSeconds = 1.6;

let animations = css`
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes colors {
    0% {
      stroke: #4285f4;
    }
    50% {
      stroke: #388e3c;
    }
    100% {
      stroke: #4285f4;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: ${offset};
    }
    50% {
      stroke-dashoffset: ${offset / 4};
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform: rotate(450deg);
    }
  }
`;

let classes = css`
  .container {
    display: inline-block;
  }

  .spinner {
    -webkit-animation: ${animations.rotator} ${durationSeconds}s linear infinite;
    -moz-animation: ${animations.rotator} ${durationSeconds}s linear infinite;
    -o-animation: ${animations.rotator} ${durationSeconds}s linear infinite;
    animation: ${animations.rotator} ${durationSeconds}s linear infinite;
  }

  .path {
    stroke-dasharray: ${offset};
    stroke-dashoffset: 0;
    stroke-width: 5;
    stroke-line-cap: round;
    fill: none;
    transform-origin: center;
    animation: ${animations.dash} ${durationSeconds}s ease-in-out infinite, ${animations.colors} ${durationSeconds * 4}s ease-in-out infinite;
    -webkit-animation: ${animations.dash} ${durationSeconds}s ease-in-out infinite, ${animations.colors} ${durationSeconds * 4}s ease-in-out infinite;
    -moz-animation: ${animations.dash} ${durationSeconds}s ease-in-out infinite, ${animations.colors} ${durationSeconds * 4}s ease-in-out infinite;
    -o-animation: ${animations.dash} ${durationSeconds}s ease-in-out infinite, ${animations.colors} ${durationSeconds * 4}s ease-in-out infinite;
  }
`;

export default function Spinner(props) {
  let { className, size = 90, style = {} } = props;

  return (
    <div style={{ ...style }} className={classnames(classes.container, className)}>
      <svg className={classes.spinner} width={size} height={size} viewBox="0 0 66 66">
        <path
          opacity="0.2"
          fill="#000"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
          s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
          c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path
          fill="#000"
          d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
          C22.32,8.481,24.301,9.057,26.013,10.047z"
        >
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite" />
        </path>
      </svg>
    </div>
  );
}
