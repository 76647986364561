import { autobind } from 'core-decorators';
import addDefaultEventListener from './add-default-event-listener';

function querySelectorAll(container, selector) {
  let nodeList = container.querySelectorAll(selector);
  return Array.prototype.slice.call(nodeList);
}

@autobind
class FocusManager {
  constructor() {
    this.isAdjustingFocus = false;
    this.next = null;

    addDefaultEventListener('keydown', this.handleDocumentKeyDown);
    addDefaultEventListener('focusin', this.handleDocumentFocusIn);
  }

  addContainer(container, priority) {
    this.removeContainer(container);

    let c = this;
    while (c.next && priority <= c.next.priority) {
      c = c.next;
    }

    c.next = {
      container: container,
      priority: priority,
      next: c.next,
    };

    if (this.next) {
      this.ensureFocusIsValid();
    }
  }

  removeContainer(container) {
    let c = this;
    while (c.next) {
      if (c.next.container === container) {
        c.next = c.next.next;
      } else {
        c = c.next;
      }
    }

    if (this.next) {
      this.ensureFocusIsValid();
    }
  }

  handleDocumentKeyDown(e) {
    if (e.defaultPrevented) {
      return;
    }

    let keyCode = e.which || e.keyCode;
    if (keyCode === 9) {
      this.moveFocus(e.shiftKey ? -1 : 1);
      e.preventDefault();
    }
  }

  handleDocumentFocusIn() {
    if (this.isAdjustingFocus) {
      return;
    }

    this.ensureFocusIsValid();
  }

  ensureFocusIsValid() {
    let currentFocus = document.activeElement;

    if (currentFocus && !this.focusIsValid(currentFocus)) {
      this.moveFocus(1);
      //Or maybe remove focus
      //currentFocus.blur();
    }
  }

  elementIsVisible(element) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
  }

  focusIsValid(element) {
    if (element.getAttribute('disabled')) {
      return false;
    }

    if (this.next && !this.next.container.contains(element)) {
      return false;
    }

    if (element.classList.contains('datePicker')) {
      return false;
    }

    return true;
  }

  moveFocus(increment) {
    if (this.isAdjustingFocus) {
      return;
    }

    this.isAdjustingFocus = true;

    let focusables = querySelectorAll(
      (this.next && this.next.container) || document,
      'a[href], area[href], input, select, textarea, button, iframe, object, embed, *[tabIndex], *[contenteditable]'
    )
      .filter(this.elementIsVisible)
      .filter((e) => e.getAttribute('tabIndex') != '-1');

    let currentFocus = document.activeElement;
    let numFocusables = focusables.length;
    let currentIndex = focusables.indexOf(currentFocus);
    let index = currentIndex;
    let nextFocus = null;

    for (let i = 0; i < numFocusables; i++) {
      index += increment;

      if (index < 0) {
        index = numFocusables - 1;
      } else if (index > numFocusables - 1) {
        index = 0;
      }

      let element = focusables[index];

      if (this.focusIsValid(element)) {
        nextFocus = element;
        break;
      }
    }

    if (nextFocus !== currentFocus) {
      if (nextFocus) this.focusWithoutScrolling(nextFocus);
      else currentFocus.blur();
    }

    this.isAdjustingFocus = false;
  }

  focusWithoutScrolling(el) {
    let scrollHierarchy = [];

    let parent = el.parentNode;
    while (parent) {
      scrollHierarchy.push([parent, parent.scrollLeft, parent.scrollTop]);
      parent = parent.parentNode;
    }

    el.focus();

    scrollHierarchy.forEach(function (item) {
      let el = item[0];

      if (el.scrollLeft != item[1]) el.scrollLeft = item[1];

      if (el.scrollTop != item[2]) el.scrollTop = item[2];
    });
  }
}

export default new FocusManager();
