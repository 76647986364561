import { INC_NUMBER_RENDERED_PAGES, SET_CURRENT_PRESENTATION } from '../types';

export default function presentation(state = {}, action = {}) {
  switch (action.type) {
    case SET_CURRENT_PRESENTATION:
      return { id: action.presentationId, renderedPages: 0 };
    case INC_NUMBER_RENDERED_PAGES:
      return { ...state, renderedPages: state.renderedPages + 1 };
    default:
      return state;
  }
}
