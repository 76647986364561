import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_CONFIG_SET, USER_FETCHED, SET_MODULE, SET_FOTO } from '../types';

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return action.user;
    case USER_FETCHED:
      return { ...state, ...action.user };
    case USER_LOGGED_OUT:
      return action.user;
    case SET_MODULE:
      return { ...state, moduloAtual: action.modulo };
    case USER_CONFIG_SET:
      return { ...state, configuracao: action.config };
    case SET_FOTO:
      return { ...state, fotoUltimaAlteracao: action.fotoUltimaAlteracao };
    default:
      return state;
  }
}
