import jwtDecode from 'jwt-decode';

import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXPIRATION_TIME,
  LAST_REQUEST_DATE,
  EXTERNAL_ACCESS_TOKEN,
  MFA_TOKEN,
  TEMP_CLEAR,
} from 'src/types';
import {
  USER_TOKEN,
  USER_EXTERNAL_TOKEN,
  DATA_ULTIMA_REQUISICAO,
  USER_AUTH_TYPE,
} from 'src/types'; // TODO:autenticação antiga

const authStorage = {
  isValidToken(accessToken) {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  },

  tokenHasLanguage(accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const hasLanguage = decodedToken.idioma != null;
    return hasLanguage;
  },

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },

  getMFAToken() {
    return localStorage.getItem(MFA_TOKEN);
  },

  getRefresToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  },

  getTokenExpirationTime() {
    return localStorage.getItem(TOKEN_EXPIRATION_TIME);
  },

  getExternalAccessToken() {
    return localStorage.getItem(EXTERNAL_ACCESS_TOKEN);
  },

  removeAccessToken() {
    return localStorage.removeItem(ACCESS_TOKEN);
  },

  removeRefreshToken() {
    return localStorage.removeItem(REFRESH_TOKEN);
  },

  removeTokenExpirationTime() {
    return localStorage.removeItem(TOKEN_EXPIRATION_TIME);
  },

  removeExternalAccessToken() {
    return localStorage.removeItem(EXTERNAL_ACCESS_TOKEN);
  },

  setAccessToken(token) {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
    }
  },

  setMFAToken(token) {
    if (token) {
      localStorage.setItem(MFA_TOKEN, token);
    }
  },

  setRefreshToken(token) {
    if (token) {
      localStorage.setItem(REFRESH_TOKEN, token);
    } else {
      localStorage.removeItem(REFRESH_TOKEN);
    }
  },

  setTokenExpirationTime(time) {
    if (time) {
      localStorage.setItem(TOKEN_EXPIRATION_TIME, time);
    } else {
      localStorage.removeItem(TOKEN_EXPIRATION_TIME);
    }
  },

  setLastRequestDate() {
    const accessToken = this.getAccessToken();
    if (this.isValidToken(accessToken)) {
      localStorage.setItem(LAST_REQUEST_DATE, Date());
    }
  },

  setExternalAccessToken(token) {
    if (token) {
      localStorage.setItem(EXTERNAL_ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(EXTERNAL_ACCESS_TOKEN);
    }
  },

  getLastRequestDate() {
    try {
      const dataUltimaRequisicao = localStorage.getItem(LAST_REQUEST_DATE);
      if (dataUltimaRequisicao) return new Date(dataUltimaRequisicao);
    } catch (error) {
      return new Date(1901, 1, 1);
    }

    const accessToken = this.getAccessToken();
    if (this.isValidToken(accessToken)) {
      localStorage.setItem(LAST_REQUEST_DATE, Date());
    }
  },

  // TODO:autenticação antiga
  setAuthType(authType) {
    localStorage.setItem(USER_AUTH_TYPE, JSON.stringify(authType));
  },

  // TODO:autenticação antiga
  getAuthType() {
    try {
      const authType = localStorage.getItem(USER_AUTH_TYPE);
      return JSON.parse(authType);
    } catch (error) {
      return 0;
    }
  },

  clearAllStorage() {
    if (!localStorage.getItem(TEMP_CLEAR)) {
      localStorage.clear();
      localStorage.setItem(TEMP_CLEAR, true);
    }
  },
};

export default authStorage;
