import parse from './parse';
import stringify from './stringify';
import scopify from './scopify';
import unnest from './unnest';

let cache = {};

export default function (texts, ...values) {
  let textsAndValues = [texts[0]];
  for (let i = 1; i < texts.length; i++) {
    textsAndValues.push(values[i - 1]);
    textsAndValues.push(texts[i]);
  }

  let originalCss = textsAndValues.join('');

  if (cache[originalCss]) return cache[originalCss];

  let cssContent = parse(originalCss);
  let classMap = scopify(cssContent);
  cssContent = unnest(cssContent).groups;

  let processedCss = stringify(cssContent);

  let style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = processedCss;
  document.getElementsByTagName('head')[0].appendChild(style);

  return (cache[originalCss] = classMap);
}
