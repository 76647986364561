import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import http from 'src/services/httpService';
import Button from 'src/componentes/button';
import Dialog from 'src/componentes/dialog';

const SenhaForte = ({ history, ...rest }) => {
  const intl = useIntl();
  const ref = useRef();

  const handleClose = () => {
    if (ref.current) ref.current.close();
  };

  const setVisualizouAlerta = async (view) => {
    try {
      await http.post('/Colaborador/AlterarAlerta', {
        visualizou: view,
      });

    } catch (err) {
      errorHandler(err);
    }
  };

  const handleSend = () => {
    handleClose();
    setVisualizouAlerta(true);
  };

  return (
    <Dialog ref={ref} onRequestClose={handleClose} {...rest}>
      <div className="text-center pb-4 px-4" style={{ width: 430, height: 439 }}>
        <div className="mb-4">
          <img alt="Senha Forte" src="/password.svg" style={{ width: 200 }} />
          <h1 style={{ margin: 0, fontSize: 24 }} className="font-weight-bolder mt-3">
            <FormattedMessage id="label.ganheMaisSeguranca"></FormattedMessage>            
          </h1>
        </div>
        <p style={{ fontSize: 15 }}>
          {intl.formatMessage({ id: 'label.redefinirPadraoSenhaForte' })}
        </p>
        <div className="text-center" style={{ paddingTop: 25}}>
          <Button onClick={handleSend} color="primary" style={{ width: 120, height: 45}}>
              <FormattedMessage id="label.entendo"></FormattedMessage>
          </Button>
        </div>
      </div>          
    </Dialog>
  );
};

export default SenhaForte;
