import { useState } from 'react';
import ReactGA from 'react-ga4';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ContentManager from 'src/componentes/content-manager';
import MdiIcon from 'src/componentes/mdi-icon';
import useAuth from 'src/hooks/useAuth';
import FormDemo from './FormDemo';
import LogoModulo from './LogoModulo';
import { MODULOS } from 'src/utils/constants';

function NavbarModules() {
  const intl = useIntl();
  const history = useHistory();

  const { module, modules, setModule, user } = useAuth();

  const [demoId, setDemoId] = useState(null);

  const renderDemo = () => (
    <Badge className="w-100">
      {intl.formatMessage({ id: 'label.soliciteUmaDemo' })}
    </Badge>
  );

  const handleFormDemo = (m) => {
    ContentManager.addContent(<FormDemo module={m} />);
  };

  const handleChangeModule = (m) => {
    ReactGA.event('troca_de_modulo', {
      category: 'modulo_navbar',
      action: `${m?.abreviacao} - ${m?.nome}`,
      label: user?.nome,
    });
    setModule(m);
    history.push('/');
  };

  const selectedModuleSize = module?.icone === 'grid' ? 38 : 42;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className="nav-flag">
        <div className="d-flex align-items-center mt-1">
          <div style={{ fontSize: 15 }}>
            <LogoModulo module={module} size={selectedModuleSize} />
          </div>
          <span className="mr-1 ml-2 text-lg text-dark">{module?.nome}</span>
          <MdiIcon icon="chevron-down" size={18} />
        </div>
      </DropdownToggle>
      <DropdownMenu right style={{ minWidth: 280 }}>
        {modules &&
          modules.length > 0 &&
          modules
            .filter(
              (e) => e.visivel && e.id !== MODULOS.INTELIGENCIA_ARTIFICIAL
            )
            .map((m) => {
              const isDemo = demoId === m.id;
              const show = m.exibir;
              const active = m.id === module.id;
              const localModuleSize = m?.icone === 'grid' ? 38 : 42;

              return (
                <DropdownItem
                  style={{ minHeight: 35 }}
                  className={!show ? 'text-muted cursor-inherit' : ''}
                  onMouseEnter={() => !show && setDemoId(m.id)}
                  onMouseLeave={() => setDemoId(null)}
                  active={active}
                  onClick={() =>
                    show ? handleChangeModule(m) : handleFormDemo(m)
                  }
                >
                  {isDemo ? (
                    renderDemo()
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="w-25">
                        <LogoModulo
                          module={m}
                          active={active}
                          size={localModuleSize}
                        />
                      </div>
                      <div className="w-75">
                        <span className="align-middle">{m.nome}</span>
                      </div>
                    </div>
                  )}
                </DropdownItem>
              );
            })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default NavbarModules;
