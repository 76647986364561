import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useAuth from 'src/hooks/useAuth';
import Input from 'src/componentes/input';
import MdiIcon from 'src/componentes/mdi-icon';

const satisfactionImagePaths = [
  {
    path: '/static/images/csat/angry.png',
    subTitleResourceId: 'muitoInsatisfeito',
  },
  {
    path: '/static/images/csat/disappointed.png',
    subTitleResourceId: 'insatisfeito',
  },
  {
    path: '/static/images/csat/neutral-emoticon.png',
    subTitleResourceId: 'indiferente',
  },
  {
    path: '/static/images/csat/smiling-face-with-heart.png',
    subTitleResourceId: 'satisfeito',
  },
  {
    path: '/static/images/csat/in-love.png',
    subTitleResourceId: 'muitoSatisfeito',
  },
];

const CSAT = () => {
  const intl = useIntl();
  const { setPesquisaCSAT } = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const [display, setDisplay] = useState(false);

  const [form, setForm] = useState({});

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setDisplay(true);
      }, 2000);
    } else {
      setTimeout(() => {
        setDisplay(false);
      }, 500);
    }
  }, []);

  const handleChangeForm = (field, value) => {
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleClose = () => setIsOpen(false);

  const handleSend = () => {
    handleClose();
    setPesquisaCSAT({ nota: form.nota, texto: form.texto });
  };

  const renderItem = (satisfationImage, index) => {
    const isSelected = form.nota === index;

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          onClick={() => handleChangeForm('nota', index)}
          className="d-inline-flex justify-content-center align-items-center text-white rounded-lg cursor-pointer opacity-hover"
          style={{
            height: '40px',
            width: '40px',
            background: isSelected ? '#FFEAB3' : '#FFFFFF',
            borderColor: isSelected ? '#C4C4C4' : '#F4F5F7',
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
        >
          <img src={satisfationImage.path} width={'30px'} height={'30px'} />
        </div>

        <span className="mt-1 text-sm text-muted">
          {intl.formatMessage({ id: satisfationImage.subTitleResourceId })}
        </span>
      </div>
    );
  };

  const sendIsDisabled = form.nota === undefined;

  return display ? (
    <div
      className="p-4 shadow-xl border position-fixed font-weight-bold bg-white text-center rounded-top-lg overflow-hidden"
      style={{
        zIndex: 100,
        bottom: 0,
        right: 20,
        maxWidth: 550,
        display: `${display ? 'block' : 'none'}`,
        animation: `${
          isOpen ? 'openBottom' : 'closeBottom'
        } 0.5s ease-in-out forwards`,
      }}
    >
      <div className="d-flex justify-content-end mb-3">
        <MdiIcon
          className="cursor-pointer"
          onClick={handleClose}
          icon="close"
        />
      </div>
      <div>{intl.formatMessage({ id: 'pesquisaCSATPergunta' })}</div>
      <div className="d-flex justify-content-around mt-3 mb-4">
        {satisfactionImagePaths?.map((satisfationImage, index) =>
          renderItem(satisfationImage, index)
        )}
      </div>
      <div>{intl.formatMessage({ id: 'pesquisaCSATMotivo' })}</div>
      <div className="d-flex align-items-center mt-3">
        <div style={{ flexGrow: 6, flexBasis: 0 }}>
          <Input
            style={{ marginBottom: '-1rem' }}
            model={{
              label: '',
              value: form.texto,
              requestChange: (v) => handleChangeForm('texto', v),
            }}
          />
        </div>
        <a
          onClick={() => !sendIsDisabled && handleSend()}
          className={`ml-3 ${
            sendIsDisabled ? 'text-muted' : 'text-primary cursor-pointer'
          }`}
          style={{
            flexGrow: 1,
            flexBasis: 0,
            textDecoration: sendIsDisabled ? 'none' : '',
          }}
        >
          {intl.formatMessage({ id: 'label.enviar' })}
        </a>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CSAT;
