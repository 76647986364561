import React, { createContext, useReducer, useEffect } from 'react';

import errorHandler from 'src/utils/error-handler';
import http from 'src/services/httpService';

const initialState = {
  configuracaoSistema: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONFIGURACAO_SISTEMA': {
      const { config } = action.payload;
      return {
        ...state,
        configuracaoSistema: config,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const ConfiguracaoSistemaContext = createContext({
  ...initialState,
  getConfiguracaoSistema: () => {},
});

export const ConfiguracaoSistemaProvider = ({ children, user }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getConfiguracaoSistema = async (userLog) => {
    if (user || userLog) {
      try {
        const response = await http.post(`/ConfiguracaoSistema/ObterConfiguracoes`);

        dispatch({
          type: 'CONFIGURACAO_SISTEMA',
          payload: {
            config: response.data.model,
          },
        });
      } catch (err) {
        errorHandler(err);
      }
    }
  };

  useEffect(() => {
    getConfiguracaoSistema();
  }, []);

  return (
    <ConfiguracaoSistemaContext.Provider
      value={{
        ...state,
        getConfiguracaoSistema,
      }}
    >
      {children}
    </ConfiguracaoSistemaContext.Provider>
  );
};

export default ConfiguracaoSistemaContext;
