import React from 'react';
import { autobind } from 'core-decorators';
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap';
import InputMask from 'react-input-mask';

@autobind
class InputField extends React.Component {
  static defaultProps = {
    row: false,
    showLabel: true,
  };

  handleChange(e) {
    let { model, disabled, getAlteracao } = this.props;

    if (disabled) return;

    model && model.requestChange && model.requestChange(e.target.value);

    if (getAlteracao != null) getAlteracao();
  }

  render() {
    let {
      model,
      autoFocus,
      maxLength,
      placeholder,
      required,
      mask,
      type,
      disabled,
      componentClass,
      detail,
      row,
      parametroUrl,
      showLabel,
      style,
      beforeMaskedValueChange,
      smLabel,
      smInput,
      formStyle = {},
      ...other
    } = this.props;

    // if(parametroUrl){
    //   model.value = parametroUrl;
    // }

    let value = type == 'password' ? '******' : model.value;

    return (
      <FormGroup style={{ ...formStyle }} row={row}>
        {model.label && showLabel && (
          <Label>
            {model.label} {required ? '*' : ''}
          </Label>
        )}
        {detail ? (
          <div style={{ whiteSpace: 'pre' }} {...other}>
            {value}
          </div>
        ) : mask ? (
          <InputMask
            autoFocus={autoFocus}
            disabled={disabled}
            type={type}
            required={required}
            placeholder={placeholder}
            value={model.value || ''}
            maxLength={maxLength}
            onChange={this.handleChange}
            mask={mask}
            beforeMaskedValueChange={beforeMaskedValueChange}
            invalid={model.errors}
            {...other}
            style={{ ...style, padding: 5 }}
            className="form-control-sm form-control"
          />
        ) : (
          <Input
            invalid={model.errors}
            autoFocus={autoFocus}
            disabled={disabled}
            type={type}
            required={required}
            placeholder={placeholder}
            value={model.value || ''}
            maxLength={maxLength}
            onChange={this.handleChange}
            componentclass={componentClass}
            {...other}
            style={{ padding: 5, ...style }}
          />
        )}
        {model.erros && <FormFeedback>{model.erros}</FormFeedback>}
      </FormGroup>
    );
  }
}

export default InputField;
