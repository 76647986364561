import React, { createContext, useReducer } from 'react';

const initialState = {
  collapsed: localStorage.getItem('COLLAPSED_SIDEBAR') == 'true' || false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_SIDEBAR_STATUS': {
      const newValue = !state.collapsed;
      localStorage.setItem('COLLAPSED_SIDEBAR', newValue);

      return {
        ...state,
        collapsed: newValue,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const SidebarContext = createContext({
  ...initialState,
  changeStatus: () => {},
});

export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeStatus = () => {
    dispatch({
      type: 'CHANGE_SIDEBAR_STATUS',
    });
  };

  return (
    <SidebarContext.Provider
      value={{
        ...state,
        changeStatus,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
