import React from 'react';

export default function errorHandler(error, intl) {
  let errorMessage = intl.formatMessage({ id: 'label.ocorreuUmErro' });

  if (error.response) {
    if (error.response.data && error.response.data.errorMessage == 98) {
      return Promise.reject(error.response.data);
    }

    if (error.response.status === 400) {
      errorMessage = error.response.data;
    }

    if (error.response.data.errorMessage) {
      errorMessage = error.response.data.errorMessage;
    }
  } else {
    if (error.type == 'EXPIRED_PASSWORD') {
      errorMessage = intl.formatMessage({ id: 'label.suaSenhaExpirou' });
    } else if (error.type == 'EXPIRED_SESSION') {
      errorMessage = intl.formatMessage({ id: 'label.sessaoExpirada' });
    }
  }

  return errorMessage;
}
