import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_CONFIG_SET, USER_FETCHED, SET_MODULE, USER_TOKEN, SET_FOTO } from '../types';
import api from '../api';

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});

export const userLoggedOut = (user) => ({
  type: USER_LOGGED_OUT,
  user,
});

export const userSetConfig = (config) => ({
  type: USER_CONFIG_SET,
  config,
});

export const userFetched = (user) => ({
  type: USER_FETCHED,
  user,
});

export const moduleFetched = (modulo) => ({
  type: SET_MODULE,
  modulo,
});

export const fotoFetched = (fotoUltimaAlteracao) => ({
  type: SET_FOTO,
  fotoUltimaAlteracao,
});

export const setModule = (modulo) => (dispatch) =>
  api.user.setModule(modulo).then((user) => {
    dispatch(moduleFetched(user.moduloAtual));
    localStorage.setItem(USER_TOKEN, JSON.stringify(user.jwt));
  });

export const fetchCurrentUser = () => (dispatch) => api.user.fetchCurrentUser().then((user) => dispatch(userFetched(user)));
