export default function parse(css) {
  let index = 0;
  let char = css[0];

  function move(count = 1) {
    index += count;
    char = css[index];
  }

  function readWhile(fn) {
    let s = '';
    while (char && fn()) {
      s += char;
      move();
    }
    return s;
  }

  function parseContent() {
    let content = [];

    while (char) {
      readWhile((c) => /\s/.test(c));

      let part = readWhile(() => char != ';' && char != '{' && char != '}').trim();

      if (char == ';') {
        move();
        if (part) {
          content.push(part);
        }
      } else if (char == '{') {
        move();
        content.push({
          header: part.trim(),
          content: parseContent(),
        });
      } else {
        if (char == '}') {
          move();
        }
        if (part) {
          content.push(part);
        }
        break;
      }
    }

    return content;
  }

  return parseContent();
}
