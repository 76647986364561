import { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import clsx from 'clsx';

import css from 'src/utils/css';

const Drawer = ({
  open,
  close,
  size: sizeSuggestion,
  position,
  header,
  title,
  icon,
  children,
  padding,
  actions,
  showBackdrop,
}) => {
  const [size] = useState(sizeSuggestion);
  const [display, setDisplay] = useState(open);

  useEffect(() => {
    const body = document.body;

    if (open) {
      body.classList.add('drawer-open');
      setDisplay(true);
    } else if (display) {
      setTimeout(() => {
        body.classList.remove('drawer-open');
        setDisplay(false);
      }, 500);
    }
  }, [open]);

  const classes = css`
    .backdrop {
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: ${display ? 'flex' : 'none'};
      animation: ${open ? 'fadeIn' : 'fadeOut'} 0.2s ease-in-out forwards;
    }

    .backdropOff {
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: ${display ? 'flex' : 'none'};
      animation: ${open ? 'fadeIn' : 'fadeOut'} 0.2s ease-in-out forwards;
    }

    .container {
      max-width: 100%;
      flex: 1 0 auto;
      display: ${display ? 'flex' : 'none'};
      outline: 0;
      z-index: 1000;
      position: fixed;
      flex-direction: column;
      background: #fff;
      box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
        0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
      overflow: auto;
    }

    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: ${padding ? '15px 20px' : ''};
      padding-bottom: 0px;
      z-index: 3;
      position: sticky;
      top: 0;
      background: #fff;
      align-items: center;
    }

    .actions {
      display: flex;
      padding: ${padding ? '15px 20px' : ''};
    }

    .headerTitle {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      h4 {
        margin-bottom: 0;
        font-size: 16px;
      }
    }

    .body {
      padding: ${padding ? '15px 20px' : ''};
    }

    .closeIcon {
      cursor: pointer;
    }

    .checkIcon {
      cursor: pointer;
      color: #3b82ec;
    }

    .right {
      top: 0;
      right: -${size}px;
      width: ${size}px;
      height: 100%;
      animation: ${open ? 'openRight' : 'closeRight'} 0.5s ease-in-out forwards;
    }

    .left {
      top: 0;
      left: -${size}px;
      width: ${size}px;
      height: 100%;
      animation: ${open ? 'openLeft' : 'closeLeft'} 0.5s ease-in-out forwards;
    }

    .bottom {
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${size}px;
      animation: ${open ? 'openBottom' : 'closeBottom'} 0.5s ease-in-out
        forwards;
    }
  `;

  return display ? (
    <>
      <div
        onClick={close}
        className={showBackdrop ? classes.backdrop : classes.backdropOff}
      />
      <div className={clsx(classes.container, classes[position])}>
        {header && (
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              {icon}
              <h4 className="text-primary">{title}</h4>
            </div>
            <Icon.X className={classes.closeIcon} onClick={close} size={18} />
          </div>
        )}
        <div className={classes.body}>{children}</div>
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
    </>
  ) : (
    <></>
  );
};

Drawer.defaultProps = {
  size: 430,
  position: 'right',
  header: true,
  icon: null,
  padding: true,
  actions: null,
  showBackdrop: true,
};

export default Drawer;
