async function encryptString(inputString, secretKey) {
  const encoder = new TextEncoder('utf-8');
  const data = encoder.encode(inputString);

  const importedKey = await crypto.subtle.importKey(
    'raw',
    encoder.encode(secretKey),
    'AES-CBC',
    false,
    ['encrypt']
  );

  const iv = crypto.getRandomValues(new Uint8Array(16));

  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-CBC', iv },
    importedKey,
    data
  );

  return {
    encryptedData: btoa(
      String.fromCharCode.apply(null, new Uint8Array(encryptedData))
    ),
    iv: btoa(String.fromCharCode.apply(null, iv)),
  };
}

export default encryptString;
