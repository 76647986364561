import { combineReducers } from '@reduxjs/toolkit';
import user from './user';
import notifications from './notifications';
import language from './language';
import relationshipsTree from './relationships-tree';
import presentation from './presentation';
import messages from './messages';
import ganhosPorAcao from './ganhos-por-acao';

const rootReducer = combineReducers({
  language,
  user,
  notifications,
  relationshipsTree,
  presentation,
  messages,
  ganhosPorAcao,
});

export default rootReducer;
