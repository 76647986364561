import React from 'react';
import svgs from './Svgs';

export default function NewSvgIcon({ icon, size, color, style, className }) {
  const SvgData = svgs[icon] || null;

  return (
    <div
      className={className}
      style={{
        ...style,
        fill: color,
        width: '25px',
        stroke: color,
      }}
    >
      {SvgData && (
        <SvgData
          style={{
            width: `${size ? size + (isNaN(size) ? '' : 'px') : 'inherit'}`,
            height: `${size ? size + (isNaN(size) ? '' : 'px') : 'inherit'}`,
          }}
        />
      )}
    </div>
  );
}
