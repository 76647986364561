import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MessageCircle } from 'react-feather';
import { useIntl } from 'react-intl';

import { setMessages } from 'src/actions/messages';
import { NavbarDropdown, NavbarDropdownItem } from './NavbarDropdown';
import ConfigApp from 'src/services/configApp';
function NavbarMessage() {
  const intl = useIntl();
  const history = useHistory();
  const messages = useSelector((state) => state.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMessages());
  }, [dispatch]);

  const handleAllClick = () => history.push('/minhas-informacoes/mensagem');

  return (
    <NavbarDropdown
      header={intl.formatMessage({ id: 'novasMensagens' })}
      footer={intl.formatMessage({ id: 'exibirTodasMensagens' })}
      onFooterClick={handleAllClick}
      icon={MessageCircle}
      count={messages ? messages.length : 0}
      showBadge={messages && messages.length > 0}
    >
      <div style={{ maxHeight: 450 }} className="overflow-auto">
        {messages &&
          messages.length > 0 &&
          messages.map((item, key) => {
            return (
              <NavbarDropdownItem
                key={key}
                icon={
                  <img
                    className="avatar img-fluid rounded-circle"
                    src={`${
                      ConfigApp.ApiUrl
                    }/ArquivoSistema/DownloadImagem?guid=${
                      item.remetente.fotoArquivo &&
                      item.remetente.fotoArquivo.guid
                    }`}
                  />
                }
                title={item.remetente.nome}
                description={item.texto}
                time={item.dataOrder}
                spacing
              />
            );
          })}
      </div>
    </NavbarDropdown>
  );
}

export default NavbarMessage;
