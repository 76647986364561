let patchedEvents = {};

let originalAddEventListener = document.addEventListener;
let originalRemoveEventListener = document.removeEventListener;

document.addEventListener = function (type, fn, capture) {
  if (type in patchedEvents) {
    patchedEvents[type].listeners.push(fn);
  } else {
    return originalAddEventListener.call(this, type, fn, capture);
  }
};

document.removeEventListener = function (type, fn, capture) {
  if (type in patchedEvents) {
    let index = patchedEvents[type].listeners.indexOf(fn);
    if (index !== -1) {
      patchedEvents[type].listeners.splice(index, 1);
    }
  } else {
    return originalRemoveEventListener.call(this, type, fn, capture);
  }
};

function patchEventHandler(type) {
  let listeners = [];
  let defaultListeners = [];

  originalAddEventListener.call(document, type, function (e) {
    for (let i = 0; i < listeners.length; i++) {
      listeners[i](e);
    }

    for (let i = 0; i < defaultListeners.length; i++) {
      defaultListeners[i](e);
    }
  });

  patchedEvents[type] = { listeners, defaultListeners };
}

export default function addDefaultEventHandler(type, fn) {
  if (!(type in patchedEvents)) {
    patchEventHandler(type);
  }

  patchedEvents[type].defaultListeners.push(fn);
}
