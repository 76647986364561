import React, { useEffect, useState, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import * as Icon from 'react-feather';
import clsx from 'clsx';

import MdiIcon from 'src/componentes/mdi-icon';
import useAuth from 'src/hooks/useAuth';
import useSidebar from 'src/hooks/useSidebar';
import Drawer from 'src/componentes/drawer';
import MaterialIcon from 'src/componentes/MaterialIcon';
import NewSvgIcon from 'src/componentes/NewSvgIcon';
import SvgIcon from 'src/componentes/svg-icon';

const initOpenRoutes = (currentModule, location) => {
  const pathName = location.pathname;

  let _routes = {};

  if (!currentModule) return _routes;

  currentModule.menu.forEach((route, index) => {
    const isActive = pathName.indexOf(route.link) === 0;
    const isOpen = route.open;

    _routes = Object.assign({}, _routes, { [index]: isActive || isOpen });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    id,
    name,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    showInfo,
    collapsedSidebar,
    to,
    clickable,
    history,
    link,
    iconType,
  }) => {
    const sidebarItemRef = useRef();
    const [height, setHeight] = useState('180px');

    useEffect(() => {
      const value = sidebarItemRef?.current?.getBoundingClientRect();

      if (value) {
        setHeight(`calc(100vh - ${value.top}px)`);
        return;
      }
    }, [sidebarItemRef, collapsedSidebar]);

    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === '/' && path === '/painel/dashboard-individual')
        ? 'active'
        : '';
    };

    return (
      <>
        <li id={id} className={clsx('sidebar-item', getSidebarItemClass(link))}>
          <span
            style={{ margin: iconType === 1 ? '-6px 0' : 0 }}
            data-toggle={showInfo && !clickable ? 'collapse' : '-'}
            className={clsx('sidebar-link', getSidebarItemClass(link), {
              ['collapsed']: !isOpen,
            })}
            onClick={!clickable ? onClick : () => history.push(link)}
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <Icon size={18} className="align-middle mr-3" />
            {showInfo && <span className="align-middle ml-2">{name}</span>}
          </span>
          {!clickable && (
            <>
              {!collapsedSidebar ? (
                <Collapse isOpen={isOpen}>
                  <ul id="item" className={'sidebar-dropdown list-unstyled'}>
                    {children}
                  </ul>
                </Collapse>
              ) : (
                <div
                  ref={sidebarItemRef}
                  className="sidebar-item-hover"
                  style={{ height }}
                >
                  <PerfectScrollbar>
                    <Collapse isOpen className="collapsed-menu">
                      <strong
                        className="sidebar-link-collapsed font-weight-bolder text-white"
                        style={{ color: '#fff', paddingBottom: 5 }}
                      >
                        {name}
                      </strong>
                      <div className="bg-primary-400">
                        <hr
                          className="bg-primary-400"
                          style={{ width: '100%', height: '1px' }}
                        />
                      </div>
                      <ul className={'sidebar-dropdown list-unstyled'}>
                        {children}
                      </ul>
                    </Collapse>
                  </PerfectScrollbar>
                </div>
              )}
            </>
          )}
        </li>
        {clickable && collapsedSidebar && (
          <UncontrolledTooltip placement="right" target={id}>
            {name}
          </UncontrolledTooltip>
        )}
      </>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, icon: Icon, location, to, collapsedSidebar, lastOne }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname === path ||
        location.pathname === path + '/' ||
        location.pathname === path + '/edit'
        ? 'active'
        : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <NavLink
          className={clsx(
            {
              ['sidebar-link']: !collapsedSidebar,
            },
            {
              ['sidebar-link-collapsed']: collapsedSidebar,
            }
          )}
          style={{
            cursor: 'pointer',
            paddingBottom: collapsedSidebar ? (lastOne ? 15 : 0) : '',
          }}
          to={to}
          activeClassName="active"
        >
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
        </NavLink>
      </li>
    );
  }
);

const SidebarSubCategory = withRouter(
  ({ id, isOpen, location, collapsedSidebar, subCategory, onClick }) => {
    const sidebarItemRef = useRef();

    useEffect(() => {
      const value = sidebarItemRef?.current?.getBoundingClientRect();

      if (value) {
        setHeight(`calc(100vh - ${value.top}px)`);
        return;
      }
    }, [sidebarItemRef, collapsedSidebar]);

    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : '';
    };

    return (
      <li
        id={id}
        className={clsx('sidebar-item', getSidebarItemClass(subCategory.link))}
      >
        <span
          data-toggle={'collapse'}
          className={clsx(`sidebar-link ${collapsedSidebar ? 'pl-3' : ''}`, {
            ['collapsed']: !isOpen,
          })}
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={onClick}
          style={{ opacity: collapsedSidebar ? 1 : 1 }}
        >
          <span className={`align-middle `}>{subCategory.label}</span>
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={'sidebar-dropdown list-unstyled'}>
            {subCategory.children &&
              subCategory.children.map((item, index) => (
                <div>
                  <SidebarItem
                    collapsedSidebar={collapsedSidebar}
                    name={`  ${item.label}`}
                    to={item.link}
                    lastOne={subCategory.children.length === index + 1}
                  />
                </div>
              ))}
          </ul>
        </Collapse>
      </li>
    );
  }
);

function Sidebar({ location }) {
  const history = useHistory();
  const { module, modules } = useAuth();

  const { changeStatus: changeSidebarStatus, collapsed: collapsedSidebar } =
    useSidebar();

  const currentModule = modules && modules.find((m) => m.id === module.id);

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  const [showInfo, setShowInfo] = useState(!collapsedSidebar);
  const [openRoutes, setOpenRoutes] = useState(() =>
    initOpenRoutes(currentModule, location)
  );
  const [subCategoryExpanded, setSubCategoryExpanded] = useState();

  const isSmallScreen = windowWidth < 600;

  const getIcon = (category) => {
    switch (category.iconType) {
      case 0:
        return (
          <SvgIcon
            icon={category.icon}
            size={22}
            style={{ marginBottom: -7 }}
          />
        );
      case 1:
        return <MdiIcon icon={category.icon} size={24} />;
      case 4:
        return (
          <MaterialIcon
            outline
            icon={category.icon}
            classCustom="font-weight-normal text-white opacity-07"
          />
        );
      case 5:
        return <NewSvgIcon color="white" icon={category.icon} size={21} />;
      default:
        const NewIcon = Icon[category.icon];
        return <NewIcon color={category.color} size={22} />;
    }
  };

  const closeAll = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );
  };

  const handleResizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
  }, []);

  useEffect(() => {
    if (collapsedSidebar) {
      closeAll();
      setShowInfo(false);
    } else {
      setTimeout(() => {
        setShowInfo(true);
      }, 350);
    }
  }, [collapsedSidebar]);

  const toggleBody = (key) => {
    closeAll(key);

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [key]: !openRoutes[key] })
    );
  };

  const renderNav = (collapse = false) => {
    const topItens =
      currentModule &&
      currentModule.menu &&
      currentModule.menu.filter((x) => x.position !== 1);
    const bottomItens =
      currentModule &&
      currentModule.menu &&
      currentModule.menu.filter((x) => x.position === 1);

    const renderItens = (list, bottom = false) => (
      <ul style={{ zIndex: 20 }} className="sidebar-nav">
        {list.map(
          (category, categoryIndex) =>
            ((category.children &&
              category.children.filter(
                (e) => !e.children || e.children.length > 0
              ).length > 0) ||
              category.clickable) && (
              <>
                <SidebarCategory
                  {...category}
                  id={`MENU-${category.key}`}
                  history={history}
                  showInfo={showInfo}
                  name={category.label}
                  icon={() => getIcon(category)}
                  isOpen={openRoutes[category.key]}
                  collapsedSidebar={collapsedSidebar}
                  clickable={category.clickable}
                  onClick={() => toggle(category.key)}
                >
                  {category.children &&
                    category.children.length > 0 &&
                    category.children.map(
                      (menu, menuIndex) =>
                        (!menu.children || menu.children.length > 0) && (
                          <>
                            {menu.children ? (
                              <SidebarSubCategory
                                subCategory={menu}
                                collapsedSidebar={collapsedSidebar}
                                lastOne={
                                  category.children.length === menuIndex + 1
                                }
                                onClick={() =>
                                  subCategoryExpanded === menu.key
                                    ? setSubCategoryExpanded()
                                    : setSubCategoryExpanded(menu.key)
                                }
                                isOpen={subCategoryExpanded === menu.key}
                              />
                            ) : (
                              <SidebarItem
                                collapsedSidebar={collapsedSidebar}
                                name={menu.label}
                                to={menu.link}
                                lastOne={
                                  category.children.length === menuIndex + 1
                                }
                              />
                            )}
                            {category.children[menuIndex + 1] &&
                              category.children[menuIndex + 1].group !=
                                menu.group && (
                                <div
                                  className={`bg-primary ${
                                    collapsedSidebar ? 'pt-1 pb-1' : ''
                                  }`}
                                >
                                  <hr
                                    className="bg-primary-400"
                                    style={{
                                      width: collapsedSidebar ? '100%' : '90%',
                                      height: '1px',
                                    }}
                                  />
                                </div>
                              )}
                          </>
                        )
                    )}
                </SidebarCategory>
                {list[categoryIndex + 1] &&
                  list[categoryIndex + 1].group != category.group && (
                    <hr
                      className="bg-primary-400 mt-2 mb-2"
                      style={{ width: '90%', height: '1px' }}
                    />
                  )}
              </>
            )
        )}
      </ul>
    );

    return (
      <nav
        className={clsx(
          'sidebar',
          {
            ['sidebar-sticky']: collapse,
          },
          {
            ['toggled']: collapse && collapsedSidebar,
          }
        )}
      >
        <div className="sidebar-content" style={{ height: '100vh' }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <PerfectScrollbar
              className={collapsedSidebar ? 'main_scrollbar' : ''}
              style={collapsedSidebar ? { overflow: 'inherit' } : {}}
            >
              {topItens && topItens.length > 0 && renderItens(topItens)}
              {bottomItens &&
                bottomItens.length > 0 &&
                renderItens(bottomItens, true)}
            </PerfectScrollbar>
          </div>
        </div>
      </nav>
    );
  };

  const toggle = (key) => {
    if (collapsedSidebar) {
      changeSidebarStatus();

      setTimeout(() => {
        toggleBody(key);
      }, 350);
    } else {
      toggleBody(key);
    }
  };

  return isSmallScreen ? (
    <Drawer
      size={260}
      padding={false}
      header={false}
      position="left"
      open={!collapsedSidebar}
      close={changeSidebarStatus}
    >
      {renderNav()}
    </Drawer>
  ) : (
    renderNav(true)
  );
}

export default withRouter(Sidebar);
